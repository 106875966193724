<!-- 반회의록 출석부 페이지 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h3 class="h3 text-white d-inline-block mb-0">
            {{ local }} {{ " " + room }}반 회의록 & 출석부 담당:
            {{ classLeader }}
          </h3>
        </div>
      </div>
      <div class="row">
        <h4 class="h4 text-white d-inline-block mb-0">
          출석부:
          <span
            v-if="team1LeaderName"
            class="me-1"
            @click.stop="attend((team = 1))"
            ><argon-button size="sm" color="secondary" style="padding: 10px"
              >1조</argon-button
            ></span
          >
          <span
            v-if="team2LeaderName"
            class="me-1"
            @click.stop="attend((team = 2))"
          >
            <argon-button size="sm" color="secondary" style="padding: 10px"
              >2조</argon-button
            >
          </span>
          <span
            v-if="team3LeaderName"
            class="me-1"
            @click.stop="attend((team = 3))"
          >
            <argon-button size="sm" color="secondary" style="padding: 10px"
              >3조</argon-button
            >
          </span>
          <span
            v-if="team4LeaderName"
            class="me-1"
            @click.stop="attend((team = 4))"
          >
            <argon-button size="sm" color="secondary" style="padding: 10px"
              >4조</argon-button
            >
          </span>
          <span
            v-if="team5LeaderName"
            class="me-1"
            @click.stop="attend((team = 5))"
          >
            <argon-button size="sm" color="secondary" style="padding: 10px"
              >5조</argon-button
            >
          </span>
          <span
            v-if="team6LeaderName"
            class="me-1"
            @click.stop="attend((team = 6))"
          >
            <argon-button size="sm" color="secondary" style="padding: 10px"
              >6조</argon-button
            >
          </span>
          <span
            v-if="team7LeaderName"
            class="me-1"
            @click.stop="attend((team = 7))"
          >
            <argon-button size="sm" color="secondary" style="padding: 10px"
              >7조</argon-button
            >
          </span>
          <span
            v-if="team8LeaderName"
            class="me-1"
            @click.stop="attend((team = 8))"
          >
            <argon-button size="sm" color="secondary" style="padding: 10px"
              >8조</argon-button
            >
          </span>
          <span
            v-if="team9LeaderName"
            class="me-1"
            @click.stop="attend((team = 9))"
          >
            <argon-button size="sm" color="secondary" style="padding: 10px"
              >9조</argon-button
            >
          </span>
          <span
            v-if="team10LeaderName"
            class="me-1"
            @click.stop="attend((team = 10))"
          >
            <argon-button size="sm" color="secondary" style="padding: 10px"
              >10조</argon-button
            >
          </span>
          <span
            v-if="team11LeaderName"
            class="me-1"
            @click.stop="attend((team = 11))"
          >
            <argon-button size="sm" color="secondary" style="padding: 10px"
              >11조</argon-button
            >
          </span>
          <span class="me-2" @click.stop="roomStatics()">
            <argon-button size="sm" color="secondary" style="padding: 10px"
              >반통계</argon-button
            >
          </span>

          <span class="me-6" @click.stop="getDataFromApi(1)">
            <argon-button size="sm" color="secondary" style="padding: 10px"
              >수업보고서/회의록</argon-button
            >
          </span>
          <span
            v-if="verboseSearchOptionClassLeader"
            class="text-end me-2"
            @click.stop="excel()"
          >
            <argon-button size="sm" color="secondary" style="padding: 10px"
              >excel</argon-button
            >
          </span>
          <span
            v-if="verboseSearchOptionBranchMO"
            class="text-end me-2"
            @click.stop="google()"
          >
            <argon-button size="sm" color="secondary" style="padding: 10px"
              >google</argon-button
            >
          </span>
          <span
            v-if="verboseSearchOptionManager"
            class="text-end"
            @click.stop="noticeAll()"
          >
            <argon-button size="sm" color="secondary" style="padding: 10px"
              >교육국 공지</argon-button
            >
          </span>
        </h4>
      </div>
    </base-header>

    <div class="container-fluid mt--6 temp">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div
              class="card-header border-0"
              style="padding-top: 5px; padding-bottom: 5px"
            >
              <div class="row align-items-center">
                <div
                  v-if="verboseSearchOptionManagerAndMentor"
                  class="col-sm-2"
                >
                  <preset-select
                    v-model="query.grade"
                    placeholder="학년"
                    size="large"
                    :choices="[
                      ['불교대학', '불교대학'],
                      ['경전대학', '경전대학'],
                    ]"
                    @click="gradeChange()"
                  />
                </div>
                <div
                  v-if="verboseSearchOptionManagerAndMentor"
                  class="col-sm-2"
                >
                  <preset-select
                    v-model="query.local"
                    placeholder="지역"
                    size="large"
                    :choices="LOCAL_NAMES_B.map((x) => [x, `${x}`])"
                  />
                </div>
                <div v-if="verboseSearchOptionBranchMO" class="col-sm-2">
                  <preset-select
                    v-model="query.room"
                    placeholder="반"
                    size="large"
                    :choices="ROOM_NUMBER2.map((x) => [x, `${x}반`])"
                  />
                </div>
                <div v-if="bsection" class="col-sm-2">
                  <preset-select
                    v-model="query.lessonName"
                    placeholder="과목"
                    size="large"
                    :choices="BLESSON_NAMES3.map((x) => [x, `${x}`])"
                  />
                </div>
                <div v-else class="col-sm-2">
                  <preset-select
                    v-model="query.lessonName"
                    placeholder="과목"
                    size="large"
                    :choices="KLESSON_NAMES3.map((x) => [x, `${x}`])"
                  />
                </div>
                <div class="col">
                  <base-button
                    class="btn-sm-1 mt-3"
                    type="secondary"
                    @click="getDataFromApi(1)"
                    >검색</base-button
                  >
                </div>
              </div>
            </div>
            <!-- t: top, b: bottom, s: left, e: right x: left & right y: top & bottom -->
            <div class="row" style="position: relative; height: 20px">
              <div
                v-if="tableType == 'attend'"
                class="col"
                style="
                  position: absolute;
                  bottom: 0px;
                  left: 120px;
                  padding-bottom: 5px;
                "
              >
                {{ team2 }} 조 출석부
                <font color="blue"
                  >&emsp;(1) 출석,&emsp;(-1) 지각,&emsp;(-2) 조퇴,&emsp;(0)
                  결석</font
                >
              </div>
            </div>

            <Modal :modal-active="modalActive" @close="closedModal">
              <div class="modal-content">
                <card type="secondary" body-classes="px-lg-5">
                  <form role="form">
                    <h6>{{ newData.team }}조 회의록</h6>
                    <div class="row mt-2">
                      <label class="form-control-label mb-0"
                        >수행연습 학생 반응
                      </label>
                    </div>
                    <div>
                      <textarea
                        v-model="newData.practiceStudentResponse"
                        class="form-control"
                        style="width: 100%"
                        rows="1"
                      ></textarea>
                    </div>
                    <div class="row mt-2">
                      <label class="form-control-label mb-0"
                        >법문 학생 반응</label
                      >
                    </div>
                    <div>
                      <textarea
                        v-model="newData.lessonStudentResponse"
                        class="form-control"
                        style="width: 100%"
                        rows="1"
                      ></textarea>
                    </div>
                    <div class="row mt-2">
                      <label class="form-control-label mb-0"
                        >주제질문 학생 반응</label
                      >
                    </div>
                    <div>
                      <textarea
                        v-model="newData.subjectStudentResponse"
                        class="form-control"
                        style="width: 100%"
                        rows="1"
                      ></textarea>
                    </div>
                    <div class="row mt-2">
                      <label class="form-control-label mb-0"
                        >수업 중 어려움 / 특이사항</label
                      >
                    </div>
                    <div>
                      <textarea
                        v-model="newData.notes"
                        class="form-control"
                        style="width: 100%"
                        rows="1"
                      ></textarea>
                    </div>
                    <div class="row mt-2">
                      <label class="form-control-label mb-0"
                        >학생 질문 / 건의사항 & 진행자 답변</label
                      >
                    </div>
                    <div>
                      <textarea
                        v-model="newData.studentProposal"
                        class="form-control"
                        style="width: 100%"
                        rows="1"
                      ></textarea>
                    </div>
                    <div class="row mt-2">
                      <label class="form-control-label mb-0"
                        >질문 / 건의사항 (진행자)</label
                      >
                    </div>
                    <div>
                      <textarea
                        v-model="newData.stepProposal"
                        class="form-control"
                        style="width: 100%"
                        rows="1"
                      ></textarea>
                    </div>
                    <div class="row mt-2">
                      <div class="col-sm-3">
                        <label class="form-control-label mb-0"
                          >사전리허설<br />(진행자)</label
                        >
                        <preset-radio
                          v-model="newData.preReview"
                          :choices="[
                            [ 1, '시행'],
                            [ 0, '미시행'],
                          ]"
                        />
                      </div>
                      <div class="col-sm-3">
                        <label class="form-control-label mb-0"
                          >수행연습올리기<br />(진행자)</label
                        >
                        <preset-radio
                          v-model="newData.practice"
                          :choices="[
                            [ 1, '시행'],
                            [ 0, '미시행'],
                          ]"
                        />
                      </div>

                      <div class="col-sm-3">
                        <label class="mb-0"
                          >사이트 출석 체크<br />(진행자)</label
                        >

                        <preset-radio
                          v-model="newData.siteAttend"
                          :choices="[
                            [ 1, '체크'],
                            [ 0, '미체크'],
                          ]"
                        />
                      </div>
                      <div class="col-sm-3">
                        <label class="form-control-label mb-0"
                          >수기 출석부 기록<br />(진행자)</label
                        >

                        <preset-radio
                          v-model="newData.manualAttend"
                          :choices="[
                            [ 1, '기록'],
                            [ 0, '미기록'],
                          ]"
                        />
                      </div>
                    </div>
                    <div class="text-center">
                      <base-button
                        type="primary"
                        class="my-2"
                        @click="onEdit(newData)"
                        >입력</base-button
                      >
                      <base-button
                        type="secondary"
                        class="my-2 ms-2"
                        @click="closedModal"
                        >닫 기</base-button
                      >
                    </div>
                  </form>
                </card>
              </div>
            </Modal>
            <Modal :modal-active="modalActive2" @close="closedModal2">
              <div class="modal-content">
                <div class="ms-3 mt-3">
                  <h2>{{ team2 }}조 출석부</h2>
                  <h3>수업명: {{ newData.lessonName }}</h3>
                  <card type="secondary" body-classes="px-lg-5">
                    <form role="form">
                      <div class="row">
                        <label class="form-control-label">{{
                          newData.student1Name
                        }}</label>
                      </div>
                      <div class="row">
                        <preset-radio
                          v-model="newData.student1Attend"
                          :choices="[
                            [ 1, '출석'],
                            [ -1, '지각'],
                            [ -2, '조퇴'],
                            [ 0, '결석'],
                          ]"
                          @change="onEdit3(newData, 1)"
                        />
                      </div>
                      <div class="row">
                        <label class="form-control-label">{{
                          newData.student2Name
                        }}</label>
                      </div>
                      <div class="row">
                        <preset-radio
                          v-model="newData.student2Attend"
                          :choices="[
                             [ 1, '출석'],
                            [ -1, '지각'],
                            [ -2, '조퇴'],
                            [ 0, '결석'],
                          ]"
                          @change="onEdit3(newData, 2)"
                        />
                      </div>
                      <div class="row">
                        <label class="form-control-label">{{
                          newData.student3Name
                        }}</label>
                      </div>
                      <div class="row">
                        <preset-radio
                          v-model="newData.student3Attend"
                          :choices="[
                            [ 1, '출석'],
                            [ -1, '지각'],
                            [ -2, '조퇴'],
                            [ 0, '결석'],
                          ]"
                          @change="onEdit3(newData, 3)"
                        />
                      </div>
                      <div class="row">
                        <label class="form-control-label">{{
                          newData.student4Name
                        }}</label>
                      </div>
                      <div class="row">
                        <preset-radio
                          v-model="newData.student4Attend"
                          :choices="[
                            [ 1, '출석'],
                            [ -1, '지각'],
                            [ -2, '조퇴'],
                            [ 0, '결석'],
                          ]"
                          @change="onEdit3(newData, 4)"
                        />
                      </div>
                      <div v-if="newData.student5Name" class="row">
                        <label class="form-control-label">{{
                          newData.student5Name
                        }}</label>
                      </div>
                      <div v-if="newData.student5Name" class="row">
                        <preset-radio
                          v-model="newData.student5Attend"
                          :choices="[
                            [ 1, '출석'],
                            [ -1, '지각'],
                            [ -2, '조퇴'],
                            [ 0, '결석'],
                          ]"
                          @change="onEdit3(newData, 5)"
                        />
                      </div>
                      <div v-if="newData.student6Name" class="row">
                        <label class="form-control-label">{{
                          newData.student6Name
                        }}</label>
                      </div>
                      <div v-if="newData.student6Name" class="row">
                        <preset-radio
                          v-model="newData.student6Attend"
                          :choices="[
                             [ 1, '출석'],
                            [ -1, '지각'],
                            [ -2, '조퇴'],
                            [ 0, '결석'],
                          ]"
                          @change="onEdit3(newData, 6)"
                        />
                      </div>
                      <div v-if="newData.student7Name" class="row">
                        <label class="form-control-label">{{
                          newData.student7Name
                        }}</label>
                      </div>
                      <div v-if="newData.student7Name" class="row">
                        <preset-radio
                          v-model="newData.student7Attend"
                          :choices="[
                            [ 1, '출석'],
                            [ -1, '지각'],
                            [ -2, '조퇴'],
                            [ 0, '결석'],
                          ]"
                          @change="onEdit3(newData, 7)"
                        />
                      </div>
                      <div v-if="newData.student8Name" class="row">
                        <label class="form-control-label">{{
                          newData.student8Name
                        }}</label>
                      </div>
                      <div v-if="newData.student8Name" class="row">
                        <preset-radio
                          v-model="newData.student8Attend"
                          :choices="[
                            [ 1, '출석'],
                            [ -1, '지각'],
                            [ -2, '조퇴'],
                            [ 0, '결석'],
                          ]"
                          @change="onEdit3(newData, 8)"
                        />
                      </div>
                      <div v-if="newData.student9Name" class="row">
                        <label class="form-control-label">{{
                          newData.student9Name
                        }}</label>
                      </div>
                      <div v-if="newData.student9Name" class="row">
                        <preset-radio
                          v-model="newData.student9Attend"
                          :choices="[
                            [ 1, '출석'],
                            [ -1, '지각'],
                            [ -2, '조퇴'],
                            [ 0, '결석'],
                          ]"
                          @change="onEdit3(newData, 9)"
                        />
                      </div>
                      <div v-if="newData.student10Name" class="row">
                        <label class="form-control-label">{{
                          newData.student10Name
                        }}</label>
                      </div>
                      <div v-if="newData.student10Name" class="row">
                        <preset-radio
                          v-model="newData.student10Attend"
                          :choices="[
                            [ 1, '출석'],
                            [ -1, '지각'],
                            [ -2, '조퇴'],
                            [ 0, '결석'],
                          ]"
                          @change="onEdit3(newData, 10)"
                        />
                      </div>
                      <div v-if="newData.student11Name" class="row">
                        <label class="form-control-label">{{
                          newData.student11Name
                        }}</label>
                      </div>
                      <div v-if="newData.student11Name" class="row">
                        <preset-radio
                          v-model="newData.student11Attend"
                          :choices="[
                            [ 1, '출석'],
                            [ -1, '지각'],
                            [ -2, '조퇴'],
                            [ 0, '결석'],
                          ]"
                          @change="onEdit3(newData, 11)"
                        />
                      </div>
                      <div class="row">&nbsp;</div>
                      <div class="row">
                        <label class="form-control-label">비고</label>
                      </div>
                      <textarea
                        v-model="newData.remark"
                        class="form-control"
                        style="width: 100%"
                        rows="2"
                      ></textarea>

                      <div class="text-center">
                        <base-button
                          v-if="add"
                          type="primary"
                          class="my-4"
                          @click="onAdd(newData)"
                          >등록 하기</base-button
                        >
                        <base-button
                          v-else
                          type="primary"
                          class="my-4"
                          @click="onEdit2(newData)"
                          >입력</base-button
                        >
                        <base-button
                          type="secondary"
                          class="my-4 ms-2"
                          @click="closedModal"
                          >닫 기</base-button
                        >
                      </div>
                    </form>
                  </card>
                </div>
              </div>
            </Modal>
            <Modal :modal-active="noticeModalActive" @close="closedModal">
              <div class="modal-content">
                <card type="secondary" body-classes="px-lg-5">
                  <form role="form">
                    <div class="row">&nbsp;</div>
                    <h2>전체 공지 및 공유</h2>
                    <div class="row mt-5"></div>
                    <!-- "교육국 공지" 텍스트 에디터, tag 사용 가능 -->
                    <div :key="htmlEditorKey">
                      <html-editor v-model="newData.notice" />
                    </div>
                    <!-- <div>
                      <textarea
                        v-model="newData.notice"
                        class="form-control"
                        style="width: 100%"
                        rows="7"
                      ></textarea>
                    </div> -->
                    <div class="text-center">
                      <base-button
                        type="primary"
                        class="my-4"
                        @click="onNoticeEdit(newData)"
                        >입력</base-button
                      >
                      <base-button
                        type="secondary"
                        class="my-4 ms-2"
                        @click="closedModal"
                        >닫 기</base-button
                      >
                    </div>
                  </form>
                </card>
              </div>
            </Modal>
            <Modal :modal-active="noticeAllModalActive" @close="closedModal">
              <div class="modal-content">
                <card type="secondary" body-classes="px-lg-5">
                  <form role="form">
                    <div class="row">&nbsp;</div>
                    <h2>교육국 공지</h2>
                    <div class="row mt-5"></div>
                    <div :key="htmlEditorKey">
                      <html-editor v-model="newData.noticeAll" />
                    </div>
                    <div class="text-center">
                      <base-button
                        type="primary"
                        class="my-4"
                        @click="noticeAllEdit(newData)"
                        >입력</base-button
                      >
                      <base-button
                        type="secondary"
                        class="my-4 ms-2"
                        @click="closedModal"
                        >닫 기</base-button
                      >
                    </div>
                  </form>
                </card>
              </div>
            </Modal>
            <Modal :modal-active="decisionModalActive" @close="closedModal">
              <div class="modal-content">
                <card type="secondary" body-classes="px-lg-5">
                  <form role="form">
                    <div class="row">&nbsp;</div>
                    <h2>결정사항</h2>
                    <div class="row mt-5"></div>
                    <div>
                      <textarea
                        v-model="newData.decision"
                        class="form-control"
                        style="width: 100%"
                        rows="7"
                      ></textarea>
                    </div>
                    <div class="text-center">
                      <base-button
                        type="primary"
                        class="my-4"
                        @click="onDecisionEdit(newData)"
                        >입력</base-button
                      >
                      <base-button
                        type="secondary"
                        class="my-4 ms-2"
                        @click="closedModal"
                        >닫 기</base-button
                      >
                    </div>
                  </form>
                </card>
              </div>
            </Modal>
            <Modal :modal-active="discussModalActive" @close="closedModal">
              <div class="modal-content">
                <card type="secondary" body-classes="px-lg-5">
                  <form role="form">
                    <div class="row">&nbsp;</div>
                    <h2>안건 논의 내용</h2>

                    <!-- 텍스트에디터 입력창 -->
                    <!-- <div class="row mt-5"></div>
                    <div :key="htmlEditorKey">
                      <html-editor v-model="newData.discuss" />
                    </div> -->
                    <div>
                      <textarea
                        v-model="newData.discuss"
                        class="form-control"
                        style="width: 100%"
                        rows="7"
                      ></textarea>
                    </div>
                    <div class="text-center">
                      <base-button
                        type="primary"
                        class="my-4"
                        @click="onDiscussEdit(newData)"
                        >입력</base-button
                      >
                      <base-button
                        type="secondary"
                        class="my-4 ms-2"
                        @click="closedModal"
                        >닫 기</base-button
                      >
                    </div>
                  </form>
                </card>
              </div>
            </Modal>
            <Modal :modal-active="specialModalActive" @close="closedModal">
              <div class="modal-content">
                <card type="secondary" body-classes="px-lg-5">
                  <form role="form">
                    <div class="row">&nbsp;</div>
                    <h2>특이사항</h2>
                    <div class="row mt-5"></div>
                    <div>
                      <textarea
                        v-model="newData.special"
                        class="form-control"
                        style="width: 100%"
                        rows="7"
                      ></textarea>
                    </div>
                    <div class="text-center">
                      <base-button
                        type="primary"
                        class="my-4"
                        @click="onSpecialEdit(newData)"
                        >입력</base-button
                      >
                      <base-button
                        type="secondary"
                        class="my-4 ms-2"
                        @click="closedModal"
                        >닫 기</base-button
                      >
                    </div>
                  </form>
                </card>
              </div>
            </Modal>
            <Modal :modal-active="teamLeaderModalActive" @close="closedModal">
              <div class="modal-content">
                <card type="secondary" body-classes="px-lg-5">
                  <form role="form">
                    <div class="row">&nbsp;</div>
                    <h2>돕는이 이름 변경</h2>
                    <div class="row mt-5"></div>

                    <div>
                      <base-input
                        v-model="newData.teamAssist"
                        type="text"
                        label="돕는이 이름"
                        name="teamAssist"
                        size="30"
                      />
                    </div>
                    <div class="text-center">
                      <base-button
                        type="primary"
                        class="my-4"
                        @click="teamAssistEdit(newData)"
                        >변경</base-button
                      >
                      <base-button
                        type="secondary"
                        class="my-4 ms-2"
                        @click="closedModal"
                        >닫 기</base-button
                      >
                    </div>
                  </form>
                </card>
              </div>
            </Modal>
            <Modal :modal-active="teamLeaderModalActive2" @close="closedModal">
              <div class="modal-content">
                <card type="secondary" body-classes="px-lg-5">
                  <form role="form">
                    <div class="row">&nbsp;</div>
                    <h2>진행자 이름 변경</h2>
                    <div class="row mt-5"></div>

                    <div>
                      <base-input
                        v-model="newData.teamLeader"
                        type="text"
                        label="진행자 이름"
                        name="teamAssist"
                        size="30"
                      />
                    </div>
                    <div class="text-center">
                      <base-button
                        type="primary"
                        class="my-4"
                        @click="teamLeaderEdit(newData)"
                        >변경</base-button
                      >
                      <base-button
                        type="secondary"
                        class="my-4 ms-2"
                        @click="closedModal"
                        >닫 기</base-button
                      >
                    </div>
                  </form>
                </card>
              </div>
            </Modal>

            <div id="aa2">
              <table v-if="tableType == 'meeting'" class="table-flush">
                <thead id="titlefix">
                  <th class="bgcolor" width="100px">
                    조<br />진행자<br />돕는이
                  </th>
                  <th class="bgcolor" width="350px">수행연습<br />학생 반응</th>
                  <th class="bgcolor" width="350px">법문<br />학생 반응</th>
                  <th class="bgcolor" width="300px">주제질문<br />학생 반응</th>
                  <th class="bgcolor" width="160px">
                    수업 중<br />어려움<br />특이사항
                  </th>
                  <th class="bgcolor" width="120px">
                    학생질문<br />건의사항<br />진행자답변
                  </th>
                  <th class="bgcolor" width="120px">
                    질문<br />건의사항<br />(진행자)
                  </th>
                  <th class="bgcolor fss" width="55px">
                    사전<br />리허설<br />(진행자)
                  </th>
                  <th class="bgcolor fss" width="55px">
                    수행연습<br />올리기<br />(진행자)
                  </th>
                  <th class="bgcolor fss" width="44px">
                    사이트<br />출석<br />체크
                  </th>
                  <th class="bgcolor fss" width="44px">
                    수기<br />출석부<br />기록
                  </th>
                </thead>
                <tbody>
                  <tr v-for="row in tableData" :key="row.index">
                    <td>
                      <argon-button
                        size="sm"
                        color="secondary"
                        style="padding: 10px"
                        class="mb-3"
                        @click="handleEdit(row)"
                        >{{ row.team }}</argon-button
                      >
                      <br />
                      <span
                        style="cursor: pointer"
                        @click="handleEditTeamLeader(row)"
                        >{{ row.teamLeader }}</span
                      >
                      <br />
                      <span
                        style="cursor: pointer"
                        @click="handleEditTeamAssist(row)"
                        >{{ row.teamAssist }}</span
                      >
                    </td>
                    <!-- <td>
                      <span @click="handleEditTeamLeader(row)">
                        {{ row.teamLeader }}</span
                      ><br /><span @click="handleEditTeamAssist(row)">{{
                        row.teamAssist
                      }}</span>
                    </td> -->
                    <td class="test align-top">
                      <p v-dompurify-html="row.practiceStudentResponse"></p>
                    </td>
                    <td class="test align-top">
                      <p v-dompurify-html="row.lessonStudentResponse"></p>
                    </td>
                    <td class="test align-top">
                      <p v-dompurify-html="row.subjectStudentResponse"></p>
                    </td>
                    <td class="test align-top">
                      <p v-dompurify-html="row.notes"></p>
                    </td>
                    <td class="test align-top">
                      <p v-dompurify-html="row.studentProposal"></p>
                    </td>
                    <td class="test align-top">
                      <p v-dompurify-html="row.stepProposal"></p>
                    </td>
                    <td class="align-top">
                      <span :class="row.statusPreReview">●</span>
                    </td>
                    <td class="align-top">
                      <span :class="row.statusPractice">●</span>
                    </td>
                    <td class="align-top">
                      <span :class="row.statusSiteAttend">●</span>
                    </td>
                    <td class="align-top">
                      <span :class="row.statusManualAttend">●</span>
                    </td>
                  </tr>
                  <tr v-for="row in tableData3" :key="row.index">
                    <td colspan="2" class="test2 text-center">
                      <argon-button
                        size="sm"
                        color="secondary"
                        style="padding: 10px"
                        @click.stop="notice2(row)"
                        >전체 공지 및 공유</argon-button
                      >
                    </td>
                    <td colspan="3" class="test">
                      <span v-dompurify-html="row.notice"></span>
                    </td>
                  </tr>
                  <tr v-for="row in tableData3" :key="row.index">
                    <td colspan="2" class="test2 text-center">
                      <argon-button
                        size="sm"
                        color="secondary"
                        style="padding: 10px"
                        @click.stop="discuss2(row)"
                        >안건 논의 내용</argon-button
                      >
                    </td>
                    <td colspan="3" class="test">
                      <span v-dompurify-html="row.discuss"></span>
                    </td>
                  </tr>
                  <tr v-for="row in tableData3" :key="row.index">
                    <td colspan="2" class="test2 text-center">
                      <argon-button
                        size="sm"
                        color="secondary"
                        style="padding: 10px"
                        @click.stop="special2(row)"
                        >특이 사항</argon-button
                      >
                    </td>
                    <td colspan="3" class="test">
                      <span v-dompurify-html="row.special"></span>
                    </td>
                  </tr>
                  <tr v-for="row in tableData3" :key="row.index">
                    <td colspan="2" class="test2 text-center">
                      <argon-button
                        size="sm"
                        color="secondary"
                        style="padding: 10px"
                        @click.stop="decision2(row)"
                      >
                        결정사항</argon-button
                      >
                    </td>
                    <td colspan="3" class="test">
                      <span v-dompurify-html="row.decision"></span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                v-if="tableType == 'attend'"
                class="table-flush"
                style="width: 1400px"
              >
                <thead class="fixed">
                  <th width="80px">강의<br />번호</th>
                  <th width="80px">날짜</th>
                  <th width="80px">요일</th>
                  <th width="180px">수업명</th>
                  <th v-if="student1Name" width="80px">
                    1<br />{{ student1Name }}
                  </th>
                  <th v-if="student2Name" width="80px">
                    2<br />{{ student2Name }}
                  </th>
                  <th v-if="student3Name" width="80px">
                    3<br />{{ student3Name }}
                  </th>
                  <th v-if="student4Name" width="80px">
                    4<br />{{ student4Name }}
                  </th>
                  <th v-if="student5Name" width="80px">
                    5<br />{{ student5Name }}
                  </th>
                  <th v-if="student6Name" width="80px">
                    6<br />{{ student6Name }}
                  </th>
                  <th v-if="student7Name" width="80px">
                    7<br />{{ student7Name }}
                  </th>
                  <th v-if="student8Name" width="80px">
                    8<br />{{ student8Name }}
                  </th>
                  <th v-if="student9Name" width="80px">
                    9<br />{{ student9Name }}
                  </th>
                  <th v-if="student10Name" width="80px">
                    10<br />{{ student10Name }}
                  </th>
                  <th v-if="student11Name" width="80px">
                    11<br />{{ student11Name }}
                  </th>
                  <th width="70px">출석</th>
                  <th width="70px">결석</th>
                  <th width="280px" class="test">비고</th>
                </thead>
                <tbody>
                  <tr v-for="row in tableData2" :key="row.index">
                    <td>
                      <argon-button
                        size="sm"
                        color="secondary"
                        style="padding: 10px"
                        @click="handleEdit2(row)"
                        >{{ row.lessonNo }}
                      </argon-button>
                    </td>

                    <td v-if="row.lessonNo < 23">{{ row.lessonDate }}</td>
                    <td v-if="row.lessonNo < 23">{{ row.lessonWeek }}</td>
                    <td v-if="row.lessonNo > 22" colspan="2">
                      {{ row.lessonDate }}
                    </td>
                    <td>{{ row.lessonName }}</td>
                    <td>{{ row.student1Attend }}</td>
                    <td v-if="student2Name">{{ row.student2Attend }}</td>
                    <td v-if="student3Name">{{ row.student3Attend }}</td>
                    <td v-if="student4Name">{{ row.student4Attend }}</td>
                    <td v-if="student5Name">{{ row.student5Attend }}</td>
                    <td v-if="student6Name">{{ row.student6Attend }}</td>
                    <td v-if="student7Name">{{ row.student7Attend }}</td>
                    <td v-if="student8Name">{{ row.student8Attend }}</td>
                    <td v-if="student9Name">{{ row.student9Attend }}</td>
                    <td v-if="student10Name">{{ row.student10Attend }}</td>
                    <td v-if="student11Name">{{ row.student11Attend }}</td>

                    <td>{{ row.sumAttend }}</td>
                    <td>{{ row.sumAbscent }}</td>
                    <td class="test">{{ row.remark }}</td>
                  </tr>
                  <tr>
                    <td colspan="15"><hr /></td>
                  </tr>
                  <tr>
                    <td colspan="4" class="test2">입학식</td>
                    <td>{{ section1Attend[0] }}</td>
                    <td>{{ section1Attend[1] }}</td>
                    <td>{{ section1Attend[2] }}</td>
                    <td>{{ section1Attend[3] }}</td>
                    <td>{{ section1Attend[4] }}</td>
                    <td>{{ section1Attend[5] }}</td>
                    <td>{{ section1Attend[6] }}</td>
                    <td>{{ section1Attend[7] }}</td>
                    <td>{{ section1Attend[8] }}</td>
                    <td>{{ section1Attend[9] }}</td>
                  </tr>
                  <tr>
                    <td
                      v-if="query.grade == '불교대학'"
                      colspan="4"
                      class="test2"
                    >
                      실천적불교사상(6회 중 <font color="red"> 3</font>회 이상)
                    </td>
                    <td v-else colspan="4" class="test2">
                      {{ section1Name }} ({{ section1Total }}회 중 <font color="red"> {{ Math.ceil(section1Total * 0.5) }}</font>회 이상)
                    </td>
                    <td>{{ section2Attend[0] }}</td>
                    <td>{{ section2Attend[1] }}</td>
                    <td>{{ section2Attend[2] }}</td>
                    <td>{{ section2Attend[3] }}</td>
                    <td>{{ section2Attend[4] }}</td>
                    <td>{{ section2Attend[5] }}</td>
                    <td>{{ section2Attend[6] }}</td>
                    <td>{{ section2Attend[7] }}</td>
                    <td>{{ section2Attend[8] }}</td>
                    <td>{{ section2Attend[9] }}</td>
                  </tr>
                  <tr>
                    <td
                      v-if="query.grade == '불교대학'"
                      colspan="4"
                      class="test2"
                    >
                      인간붓다(7회 중 <font color="red"> 4</font>회 이상)
                    </td>
                    <td v-else colspan="4" class="test2">
                      {{ section2Name }}( {{ section2Total }}회 중
                      <font color="red"> {{ Math.ceil(section2Total * 0.5)}}</font>회 이상)
                    </td>
                    <td>{{ section3Attend[0] }}</td>
                    <td>{{ section3Attend[1] }}</td>
                    <td>{{ section3Attend[2] }}</td>
                    <td>{{ section3Attend[3] }}</td>
                    <td>{{ section3Attend[4] }}</td>
                    <td>{{ section3Attend[5] }}</td>
                    <td>{{ section3Attend[6] }}</td>
                    <td>{{ section3Attend[7] }}</td>
                    <td>{{ section3Attend[8] }}</td>
                    <td>{{ section3Attend[9] }}</td>
                  </tr>
                  <tr>
                    <td
                      v-if="query.grade == '불교대학'"
                      colspan="4"
                      class="test2"
                    >
                      수행나누기/불교와사회(5회 중 <font color="red"> 3</font>회
                      이상)
                    </td>
                    <td v-else colspan="4" class="test2">
                      {{ section3Name }}( {{ section3Total }}회 중
                      <font color="red">  {{ Math.ceil(section3Total * 0.5) }}</font>회 이상)
                    </td>
                    <td>{{ section4Attend[0] }}</td>
                    <td>{{ section4Attend[1] }}</td>
                    <td>{{ section4Attend[2] }}</td>
                    <td>{{ section4Attend[3] }}</td>
                    <td>{{ section4Attend[4] }}</td>
                    <td>{{ section4Attend[5] }}</td>
                    <td>{{ section4Attend[6] }}</td>
                    <td>{{ section4Attend[7] }}</td>
                    <td>{{ section4Attend[8] }}</td>
                    <td>{{ section4Attend[9] }}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="test2">
                      즉문즉설({{ talkTotal }}회 중 <font color="red"> {{ Math.ceil(talkTotal*0.5) }}</font>회 이상)
                    </td>
                    <td>{{ talkAttend[0] }}</td>
                    <td>{{ talkAttend[1] }}</td>
                    <td>{{ talkAttend[2] }}</td>
                    <td>{{ talkAttend[3] }}</td>
                    <td>{{ talkAttend[4] }}</td>
                    <td>{{ talkAttend[5] }}</td>
                    <td>{{ talkAttend[6] }}</td>
                    <td>{{ talkAttend[7] }}</td>
                    <td>{{ talkAttend[8] }}</td>
                    <td>{{ talkAttend[9] }}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="test2">
                      실천활동({{ offlineTotal }}회 중 <font color="red"> {{ Math.ceil(offlineTotal*0.5) }}</font>회 이상)
                    </td>
                    <td>{{ offlineAttend[0] }}</td>
                    <td>{{ offlineAttend[1] }}</td>
                    <td>{{ offlineAttend[2] }}</td>
                    <td>{{ offlineAttend[3] }}</td>
                    <td>{{ offlineAttend[4] }}</td>
                    <td>{{ offlineAttend[5] }}</td>
                    <td>{{ offlineAttend[6] }}</td>
                    <td>{{ offlineAttend[7] }}</td>
                    <td>{{ offlineAttend[8] }}</td>
                    <td>{{ offlineAttend[9] }}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="test2">전체출석</td>
                    <td>{{ sumAttend[0] }}</td>
                    <td>{{ sumAttend[1] }}</td>
                    <td>{{ sumAttend[2] }}</td>
                    <td>{{ sumAttend[3] }}</td>
                    <td>{{ sumAttend[4] }}</td>
                    <td>{{ sumAttend[5] }}</td>
                    <td>{{ sumAttend[6] }}</td>
                    <td>{{ sumAttend[7] }}</td>
                    <td>{{ sumAttend[8] }}</td>
                    <td>{{ sumAttend[9] }}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="test2">
                      지각 조퇴(3회 누적:결석
                      <font color="red"> 1</font>회)-학기말 최종 적용
                    </td>
                    <td>{{ sumLate[0] }}</td>
                    <td>{{ sumLate[1] }}</td>
                    <td>{{ sumLate[2] }}</td>
                    <td>{{ sumLate[3] }}</td>
                    <td>{{ sumLate[4] }}</td>
                    <td>{{ sumLate[5] }}</td>
                    <td>{{ sumLate[6] }}</td>
                    <td>{{ sumLate[7] }}</td>
                    <td>{{ sumLate[8] }}</td>
                    <td>{{ sumLate[9] }}</td>
                  </tr>
                  <tr>
                    <td colspan="4" class="test2">
                      최종 출석 일수({{ sumTotal }}회 중 <font color="red"> {{ Math.ceil(sumTotal*0.7) }}</font>회 이상
                      졸업)
                    </td>

                    <td>{{ finalAttend[0] }}</td>
                    <td>{{ finalAttend[1] }}</td>
                    <td>{{ finalAttend[2] }}</td>
                    <td>{{ finalAttend[3] }}</td>
                    <td>{{ finalAttend[4] }}</td>
                    <td>{{ finalAttend[5] }}</td>
                    <td>{{ finalAttend[6] }}</td>
                    <td>{{ finalAttend[7] }}</td>
                    <td>{{ finalAttend[8] }}</td>
                    <td>{{ finalAttend[9] }}</td>
                  </tr>
                </tbody>
              </table>

              <table
                v-if="tableType == 'roomStatic'"
                class="table-flush"
                style="width: 1400px"
              >
                <thead>
                  <th width="80px">조</th>
                  <th width="80px">재학인원</th>
                  <th width="80px">출석</th>
                  <th width="80px">결석</th>
                  <th width="80px">지각</th>
                  <th width="80px">조퇴</th>
                </thead>
                <tbody>
                  <tr v-for="row in tableData2" :key="row.index">
                    <td>{{ row.team }}</td>
                    <td>{{ row.total }}</td>
                    <td>{{ row.attend }}</td>
                    <td>{{ row.abscent }}</td>
                    <td>{{ row.late }}</td>
                    <td>{{ row.early }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- Pagination -->
            <!-- <div class="card-footer py-4 d-flex justify-content-center">
             <common-pagination
                :rows-per-page="perPage"
                :total="total"
                :model-value="page2"
                @page-change="getDataFromApi"
              />
            </div> -->
            <!-- End Pagination -->
          </div>
          <br />

          <!-- <a href="https://youtu.be/uhslC2gnen4" target="_blank"
            >삼귀의/수행문 </a
          >&emsp;
          <a href="https://youtu.be/aVLwp4AOj3k" target="_blank"> 명상 </a
          >&emsp;
          <a href="https://youtu.be/SDDa7niyysk" target="_blank"> 사홍서원 </a
          >&emsp; -->
          <a
            href="https://docs.google.com/presentation/d/1B104F2Yr9mcV54_AXzokvwxShFf3TrbrWpLk6_SBwSg/edit#slide=id.g228f51728c3_0_0"
            target="_blank"
          >
            [ 삼귀의/수행문, 명상, 사홍서원 ] </a
          >&emsp;
          <a
            href="https://docs.google.com/document/d/1vYs_W71kWVbN89nqi3ezGX-LRwuBBKXVzc0946Qsb6o/edit"
            target="_blank"
          >
            [ 불교대 회의진행멘트 ]</a
          >&emsp;
          <a
            href="https://docs.google.com/document/d/1hCu3uJV4F19f1mEe5jkbaMREtbEV0Y8Z02CJTKD3Igw/edit"
            target="_blank"
          >
            [ 경전대 회의진행멘트 ]</a
          >&emsp;
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import * as api from "@/api";
import { ref } from "vue";
import PresetSelect from "@/components/Inputs/PresetSelect";
import PresetRadio from "@/components/Inputs/PresetRadio";
import Modal from "./Board/Modal";
import { useRouter } from "vue-router";
import moment from "moment";
import {
  BLESSON_NAMES3,
  KLESSON_NAMES3,
  LOCAL_NAMES_B,
  ROOM_NUMBER2,
  BSECTION,
  KSECTION,
} from "@/consts/class";
import { mapState } from "vuex";
// import ArgonCheckbox from "@/components/ArgonCheckbox";

export default {
  // 컴포넌트 초기화
  components: {
    PresetSelect,
    PresetRadio,
    Modal,
    HtmlEditor,
    // ArgonCheckbox,
  },

  setup() {
    let tableData = ref([]);
    let tableData2 = ref([]);
    let tableData3 = ref([]);
    const section2Attend = ref([]);
    const sumLateAttend = ref([]);
    const section3Attend = ref([]);
    const finalAttend = ref([]);
    const section1Attend = ref([]);
    const talkAttend = ref([]);
    const section4Attend = ref([]);
    const section5Attend = ref([]);
    const offlineAttend = ref([]);
    const sumAttend = ref([]);
    const sumLate = ref([]);
    const onlineTotal = ref(0);
    const talkTotal = ref(0);
    const offlineTotal = ref(0);
    const onlineTalkTotal = ref(0);
    const sumTotal = ref(0);
    const section1Total = ref(0);
    const section2Total = ref(0);
    const section3Total = ref(0);
    const section1Name = ref('');
    const section2Name = ref('');
    const section3Name = ref('');

    let team2 = ref(0);
    const newData = ref({});
    const perPage = ref(20); // 페이지당 레코드 수
    let page2 = ref(1);
    const total = ref(0); // 총 레코드 수
    const total2 = ref(0); // 총 레코드 수
    const query = ref({
      grade: "",
      post: "",
      bound: 10,
      lessonName: "",
      room: "",
      local: "",
      hidden: "",
    });
    let previewTitle = ref("");
    let student1Name = ref("");
    let student2Name = ref("");
    let student3Name = ref("");
    let student4Name = ref("");
    let student5Name = ref("");
    let student6Name = ref("");
    let student7Name = ref("");
    let student8Name = ref("");
    let student9Name = ref("");
    let student10Name = ref("");
    let student11Name = ref("");
    let team1LeaderName = ref("");
    let team2LeaderName = ref("");
    let team3LeaderName = ref("");
    let team4LeaderName = ref("");
    let team5LeaderName = ref("");
    let team6LeaderName = ref("");
    let team7LeaderName = ref("");
    let team8LeaderName = ref("");
    let team9LeaderName = ref("");
    let team10LeaderName = ref("");
    let team11LeaderName = ref("");
    let previewBody = ref("");
    let local = ref("");
    let room = ref("");
    let tableType = ref("");
    let classLeader = ref("");
    let showPreviewModal = ref(false);
    let htmlEditorKey = ref(0);
    let verboseSearchOptionManager = ref(false);
    let verboseSearchOptionManagerAndMentor = ref(false);
    let verboseSearchOptionBranchMO = ref(false);
    let verboseSearchOptionBranch = ref(false);
    let verboseSearchOptionMaster = ref(false);
    let verboseSearchOptionSemester = ref(false);
    let verboseSearchOptionClassLeader = ref(false);
    let modalActive = ref(false);
    let modalActive2 = ref(false);
    let noticeModalActive = ref(false);
    let specialModalActive = ref(false);
    let decisionModalActive = ref(false);
    let discussModalActive = ref(false);
    let teamLeaderModalActive = ref(false);
    let teamLeaderModalActive2 = ref(false);
    let bsection = ref(false);
    let ksection = ref(false);
    let noticeAllModalActive = ref(false);
    const add = ref(false);
    const header = ref([
      "조",
      "진행자/돕는이",
      "사전리허설",
      "진행시간",
      "수업분위기",
      "특이사항",
      "입력",
    ]);
    const token = api.Auth.tokenContent;

    const scrollQuery = () => {
      let wrapper_1 = document.querySelector("#aa1");
      let wrapper_2 = document.querySelector("#aa2");
      wrapper_2.scrollLeft = wrapper_1.scrollLeft;
    };
    
    if (token.grade == "경전대학") {
      query.value.grade = "경전대학";
      bsection.value = false;
    } else {
      query.value.grade = "불교대학";
      bsection.value = true;
    }

    const gradeChange = () => {
      if (query.value.grade == "경전대학") {
        bsection.value = true;
        query.value.lessonName = "입학식";
      } else if (query.value.grade == "불교대학") {
        bsection.value = false;
        query.value.lessonName = "입학식";
      }
    };

    const getDataFromApi = (page = 1) => {
      let url2 = `manage/class2/lesson?today=today&grade=${query.value.grade}&year=${token.year}&semester=${token.semester}`;

      api.get(url2).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data2 = response.data;
        const d2 = data2.data;
        if (!query.value.lessonName) {
          query.value.lessonName = d2[0].name;
        }
        tableData.value = [];
        tableType.value = "meeting";
        page2.value = page;
        const url = `manage/class2/meetingAttend?bound=${query.value.bound}
      &page=${page}&grade=${query.value.grade}&lessonName=${query.value.lessonName}&room=${query.value.room}&local=${query.value.local}`;
        api.get(url).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const data = response.data;
          const d = data.data;
          team1LeaderName.value = "";
          team2LeaderName.value = "";
          team3LeaderName.value = "";
          team4LeaderName.value = "";
          team5LeaderName.value = "";
          team6LeaderName.value = "";
          team7LeaderName.value = "";
          team8LeaderName.value = "";
          team9LeaderName.value = "";
          team10LeaderName.value = "";
          team11LeaderName.value = "";
          for (let i = 1; i <= data.rows; i++) {
            eval(
              "team" + i + "LeaderName.value = d[" + (i - 1) + "].teamLeader"
            );
          }

          if (data.rows > 0) {
            for (const row of d) {
              local.value = row.local;
              room.value = row.room;
              if (row.preReview === 1) {
                row.statusPreReview = "text-success";
              } else {
                row.statusPreReview = "text-danger";
              }
              if (row.evaluation === 1) {
                row.statusEvaluation = "text-success";
              } else {
                row.statusEvaluation = "text-danger";
              }
              if (row.siteAttend === 1) {
                row.statusSiteAttend = "text-success";
              } else {
                row.statusSiteAttend = "text-danger";
              }
              if (row.manualAttend === 1) {
                row.statusManualAttend = "text-success";
              } else {
                row.statusManualAttend = "text-danger";
              }
              if (row.practice === 1) {
                row.statusPractice = "text-success";
              } else {
                row.statusPractice = "text-danger";
              }
              row.team = row.team + "(" + row.belong + ")";
              if (row.lessonStudentResponse) {
                row.lessonStudentResponse = row.lessonStudentResponse
                  .split("\n")
                  .join("<br>");
              }
              if (row.lessonStepEvaluation) {
                row.lessonStepEvaluation = row.lessonStepEvaluation
                  .split("\n")
                  .join("<br>");
              }
              if (row.notes) {
                row.notes = row.notes.split("\n").join("<br>");
              }
              if (row.studentProposal) {
                row.studentProposal = row.studentProposal
                  .split("\n")
                  .join("<br>");
              }
              if (row.subjectStudentResponse) {
                row.subjectStudentResponse = row.subjectStudentResponse
                  .split("\n")
                  .join("<br>");
              }
              if (row.subjectStepEvaluation) {
                row.subjectStepEvaluation = row.subjectStepEvaluation
                  .split("\n")
                  .join("<br>");
              }
              if (row.practiceStudentResponse) {
                row.practiceStudentResponse = row.practiceStudentResponse
                  .split("\n")
                  .join("<br>");
              }
              if (row.practiceStepEvaluation) {
                row.practiceStepEvaluation = row.practiceStepEvaluation
                  .split("\n")
                  .join("<br>");
              }
              if (row.stepProposal) {
                row.stepProposal = row.stepProposal.split("\n").join("<br>");
              }
            }
          }

          tableData.value = d;
          total.value = data.total;
        });

        const url2 = `manage/class2/meeting?grade=${query.value.grade}&lessonName=${query.value.lessonName}&room=${query.value.room}&local=${query.value.local}`;
        api.get(url2).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const data = response.data;
          const d = data.data;
          classLeader.value = "";
          tableData3.value = [];
          if (data.rows > 0) {
            for (const row of d) {
              classLeader.value = row.classLeader;
              row.notice = row.notice.split("\n").join("<br>");
              row.noticeAll = row.noticeAll.split("\n").join("<br>");
              row.notice =
                "[교육국 공지]" + row.noticeAll + "<hr>" + row.notice;
              row.discuss = row.discuss.split("\n").join("<br>");
              row.special = row.special.split("\n").join("<br>");
              row.decision = row.decision.split("\n").join("<br>");
            }
          }
          tableData3.value = d;
        });
      });
    };

    getDataFromApi(1);

    const created = () => {
      // 페이지 생성 시 호출
      const token = api.Auth.tokenContent;
      verboseSearchOptionSemester.value =
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("manager") ||
        token.role.includes("mentor");
      verboseSearchOptionManager.value =
        token.role.includes("manager") || token.role.includes("master");
      verboseSearchOptionBranch.value =
        token.role.includes("manager") || 
        token.role.includes("master") ||
        token.role.includes("branch");
      verboseSearchOptionManagerAndMentor.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("mentor");
      verboseSearchOptionBranchMO.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("mentor") ||
        token.role.includes("branch");
      verboseSearchOptionClassLeader.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("mentor") ||
        token.role.includes("class leader") ||
        token.role.includes("branch") ||
        token.role.includes("observer");
      verboseSearchOptionMaster.value = token.role.includes("master");
      verboseSearchOptionSemester.value =
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("manager") ||
        token.role.includes("mentor");
    };

    created();

    const router = useRouter();
    const goShare = (row) => {
      router.push({
        name: "SharePractice",
        query: {
          id: row.id,
        },
      });
    };

    const goMeet = (url) => {
      window.open(url);
    };
    const closedModal = () => {
      modalActive.value = false;
      modalActive2.value = false;
      noticeModalActive.value = false;
      specialModalActive.value = false;
      discussModalActive.value = false;
      decisionModalActive.value = false;
      showPreviewModal.value = false;
      noticeAllModalActive.value = false;
      teamLeaderModalActive.value = false;
      teamLeaderModalActive2.value = false;
    };

    // 수정 모달
    const handleEdit = (row) => {
      if (token.role.includes("mentor")) {
        return;
      }
      api.get(`manage/class2/meetingAttend/${row.id}`).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        modalActive.value = !modalActive.value;
        newData.value = response.data.data;
        console.log("aa: " + JSON.stringify(response.data.data));
        if (newData.value.remark) {
          newData.value.remark = newData.value.remark.replace("<p>", "");
          newData.value.remark = newData.value.remark.replace("</p>", "");
        }
        add.value = false;
      });
    };
    // 수정 모달
    const handleEditTeamAssist = (row) => {
      if (token.role.includes("mentor")) {
        return;
      }
      if (verboseSearchOptionBranch.value) {
        teamLeaderModalActive.value = true;
        newData.value.teamAssist = row.teamAssist;
        let regex = /[^0-9]/g;
        newData.value.team = row.team.replace(regex, "");
      }
    };
    // 수정 모달
    const handleEditTeamLeader = (row) => {
      if (token.role.includes("mentor")) {
        return;
      }
      if (verboseSearchOptionBranch.value) {
        teamLeaderModalActive2.value = true;
        newData.value.teamLeader = row.teamLeader;
        let regex = /[^0-9]/g;
        newData.value.team = row.team.replace(regex, "");
      }
    };
    // 수정 모달
    const handleEdit2 = (row) => {
      if (token.role.includes("mentor")) {
        return;
      }
      htmlEditorKey.value++;
      modalActive2.value = !modalActive2.value;
      const d = {};
      d.lessonName = row.lessonName;
      d.lessonNo = row.lessonNo;
      d.remark = row.remark;
      d.student1Name = student1Name.value;
      d.student1Attend = row.student1Attend;
      d.student2Name = student2Name.value;
      d.student2Attend = row.student2Attend;
      d.student3Name = student3Name.value;
      d.student3Attend = row.student3Attend;
      d.student4Name = student4Name.value;
      d.student4Attend = row.student4Attend;
      d.student5Name = student5Name.value;
      d.student5Attend = row.student5Attend;
      d.student6Name = student6Name.value;
      d.student6Attend = row.student6Attend;
      d.student7Name = student7Name.value;
      d.student7Attend = row.student7Attend;
      d.student8Name = student8Name.value;
      d.student8Attend = row.student8Attend;
      d.student9Name = student9Name.value;
      d.student9Attend = row.student9Attend;
      d.student10Name = student10Name.value;
      d.student10Attend = row.student10Attend;
      d.student11Name = student11Name.value;
      d.student11Attend = row.student11Attend;
      newData.value = d;
      add.value = false;
    };
    const notice2 = (row) => {
      api.get(`manage/class2/meeting/${row.id}`).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        htmlEditorKey.value++;
        noticeModalActive.value = true;
        const d = response.data.data;
        newData.value = d;
      });
    };
    const noticeAll = () => {
      const url2 = `manage/class2/meeting?grade=${query.value.grade}&lessonName=${query.value.lessonName}&room=${query.value.room}&local=${query.value.local}`;
      api.get(url2).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        htmlEditorKey.value++;
        noticeAllModalActive.value = true;
        const d = response.data.data;
        newData.value = d[0];
      });
    };
    const decision2 = (row) => {
      api.get(`manage/class2/meeting/${row.id}`).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        htmlEditorKey.value++;
        decisionModalActive.value = true;
        const d = response.data.data;
        newData.value = d;
      });
    };
    const discuss2 = (row) => {
      api.get(`manage/class2/meeting/${row.id}`).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        htmlEditorKey.value++;
        discussModalActive.value = !discussModalActive.value;
        const d = response.data.data;
        newData.value = d;
        add.value = false;
      });
    };
    const special2 = (row) => {
      api.get(`manage/class2/meeting/${row.id}`).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        htmlEditorKey.value++;
        specialModalActive.value = true;
        const d = response.data.data;
        newData.value = d;
      });
    };
    // 등록
    const onAdd = () => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.post("manage/class2/meetingAttend", { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 수정
    const onEdit = (row) => {
      const data = JSON.parse(JSON.stringify(newData.value));

      api.put(`manage/class2/meetingAttend/${row.id}`, { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    const onNoticeEdit = (row) => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.put(`manage/class2/meeting/${row.id}`, { data }).then(() => {
        getDataFromApi(1);
        noticeModalActive.value = false;
      });
    };
    const noticeAllEdit = (row) => {
      const data = {};

      data.noticeAll = newData.value.noticeAll;
      data.grade = row.grade;
      data.lessonName = row.lessonName;

      api.put(`manage/class2/meeting2/${row.id}`, { data }).then(() => {
        getDataFromApi(1);
        noticeAllModalActive.value = false;
      });
    };
    const onDecisionEdit = (row) => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.put(`manage/class2/meeting/${row.id}`, { data }).then(() => {
        getDataFromApi(1);
        decisionModalActive.value = false;
      });
    };
    const onDiscussEdit = (row) => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.put(`manage/class2/meeting/${row.id}`, { data }).then(() => {
        getDataFromApi(1);
        discussModalActive.value = false;
      });
    };
    const onSpecialEdit = (row) => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.put(`manage/class2/meeting/${row.id}`, { data }).then(() => {
        getDataFromApi(1);
        specialModalActive.value = false;
      });
    };
    // 수정
    const onEdit3 = (row, student) => {
      const data = {};
      data.grade = query.value.grade;
      data.local = local.value;
      data.room = room.value;
      data.team = team2.value;
      data.lessonNo = row.lessonNo;
      data.lessonName = row.lessonName;

      if (student == 1) {
        data.name = row.student1Name;
        data.attend = row.student1Attend;
      } else if (student == 2) {
        data.name = row.student2Name;
        data.attend = row.student2Attend;
      } else if (student == 3) {
        data.name = row.student3Name;
        data.attend = row.student3Attend;
      } else if (student == 4) {
        data.name = row.student4Name;
        data.attend = row.student4Attend;
      } else if (student == 5) {
        data.name = row.student5Name;
        data.attend = row.student5Attend;
      } else if (student == 6) {
        data.name = row.student6Name;
        data.attend = row.student6Attend;
      } else if (student == 7) {
        data.name = row.student7Name;
        data.attend = row.student7Attend;
      } else if (student == 8) {
        data.name = row.student8Name;
        data.attend = row.student8Attend;
      } else if (student == 9) {
        data.name = row.student9Name;
        data.attend = row.student9Attend;
      } else if (student == 10) {
        data.name = row.student10Name;
        data.attend = row.student10Attend;
      } else if (student == 11) {
        data.name = row.student11Name;
        data.attend = row.student11Attend;
      }
      data.onlineTotal = onlineTotal.value;
      data.talkTotal = talkTotal.value;

      api
        .put(`manage/class2/attend111/${row.lessonName}`, { data })
        .then(() => {
          attend(team2.value);
        });
    };
    // 수정
    const onEdit2 = (row) => {
      const data = {};
      data.remark = row.remark;
      data.grade2 = query.value.grade;
      data.local2 = local.value;
      data.room2 = room.value;
      data.team2 = team2.value;
      data.lessonNo2 = row.lessonNo;
      console.log("aa: " + JSON.stringify(data));

      api.put(`manage/class2/meetingAttend3/${row.id}`, { data }).then(() => {
        attend(team2.value);
        modalActive2.value = false;
      });
    };
    // 수정
    const teamAssistEdit = (row) => {
      const data = {};
      data.teamAssist = row.teamAssist;
      data.grade2 = query.value.grade;
      data.local2 = local.value;
      data.room2 = room.value;
      data.team2 = row.team;

      api.put(`manage/class2/meetingAttend3/${row.id}`, { data }).then(() => {
        getDataFromApi();
        teamLeaderModalActive.value = false;
      });
    };
    // 진행자 이름 수정
    const teamLeaderEdit = (row) => {
      const data = {};
      data.teamLeader = row.teamLeader;
      data.grade2 = query.value.grade;
      data.local2 = local.value;
      data.room2 = room.value;
      data.team2 = row.team;

      api.put(`manage/class2/meetingAttend3/${row.id}`, { data }).then(() => {
        getDataFromApi();
        teamLeaderModalActive2.value = false;
      });
    };
    // 삭제하기
    const handleDelete = (row) => {
      if (confirm("삭제하시겠습니까?")) {
        api.delete_(`manage/class2/meetingAttend/${row.id}`).then(() => {
          tableData.value = tableData.value.filter((r) => r.id !== row.id);
        });
      }
    };

    const attend = (team) => {
      let begin = 0;
      let end = 0;
      if (query.value.hidden != "") {
        begin = Number(query.value.hidden.split("-")[0]);
        end = Number(query.value.hidden.split("-")[1]);
        if (begin < 1 || end > 26) {
          alert("시작번호와 끝번호는 1에서 26사이에 있어야 됩니다. 예시: 1-10");
        } else if (begin > end) {
          alert("시작번호가 끝번호보다 작아야 됩니다.");
        }
      }

      student1Name.value = "";
      student2Name.value = "";
      student3Name.value = "";
      student4Name.value = "";
      student5Name.value = "";
      student6Name.value = "";
      student7Name.value = "";
      student8Name.value = "";
      student9Name.value = "";
      student10Name.value = "";
      student11Name.value = "";
      team2.value = team;
      tableType.value = "attend";
      
      const url1 = `manage/class2/meetingAttend2?grade=${query.value.grade}&team=${team}&room=${query.value.room}&local=${query.value.local}&begin=${begin}&end=${end}`;
      api.get(url1).then((response3) => {
        if (!api.isSuccess(response3)) {
          return;
        }
        const data3 = response3.data;
        const d3 = data3.data;     

        const url2 = `manage/class2/lesson?grade=${query.value.grade}&year=${token.year}&semester=${token.semester}&special=special`;
        api.get(url2).then((response2) => {
          if (!api.isSuccess(response2)) {
            return;
          }
          const data2 = response2.data;
          const d2 = data2.data;
          onlineTotal.value = data2.rows; 
        if(query.value.grade == "경전대학") {
          section1Name.value = KSECTION[2];
          section2Name.value = KSECTION[3];
          section3Name.value = KSECTION[4];
        } else {
          section1Name.value = BSECTION[2];
          section2Name.value = BSECTION[3];
          section3Name.value = BSECTION[4];
        } 
        let section1 = [];
        let section2 = [];
        let section3 = [];

        for(const member of d2) {
          if(member.section == section1Name.value) {
            section1.push(member.lessonNo - 1);
          } else if(member.section == section2Name.value) {
            section2.push(member.lessonNo - 1);
          } else if(member.section == section3Name.value) {
            section3.push(member.lessonNo - 1);
          }
        }
        section1Total.value = section1.length;
        section2Total.value = section2.length;
        section3Total.value = section3.length; 

          const url3 = `manage/class2/talk?grade=${query.value.grade}&year=${token.year}&semester=${token.semester}&special=special`;
          api.get(url3).then((response4) => {
            if (!api.isSuccess(response4)) {
              return;
            }
            const data4 = response4.data;
            const d4 = data4.data;
            talkTotal.value = data4.rows;
            onlineTalkTotal.value = onlineTotal.value + talkTotal.value; 
        const url4 = `manage/class2/weekend?grade=${query.value.grade}&year=${token.year}&semester=${token.semester}&special=special`;
        api.get(url4).then((response5) => {
          if (!api.isSuccess(response5)) {
            return;
          }
          const data5 = response5.data;
          const d5 = data5.data;
          offlineTotal.value = data5.rows;
          sumTotal.value = onlineTotal.value + talkTotal.value + offlineTotal.value; 

          const url = `manage/class2/attend111?grade=${query.value.grade}&team=${team}&room=${query.value.room}&local=${query.value.local}&begin=${begin}&end=${end}`;
          api.get(url).then((response) => {
            if (!api.isSuccess(response)) {
              return;
            }
            const data = response.data;
            const d = data.data; 
            d.temp = "aa";
            section1Attend.value = [];
            section2Attend.value = [];
            section3Attend.value = [];
            section4Attend.value = [];
            talkAttend.value = [];
            offlineAttend.value = [];
            sumAttend.value = [];
            sumLate.value = [];
            finalAttend.value = [];
            for (let i = 0; i < data.rows; i++) {
              section1Attend.value.push(0);
              section2Attend.value.push(0);
              section3Attend.value.push(0);
              section4Attend.value.push(0);
              talkAttend.value.push(0);
              offlineAttend.value.push(0);
              sumAttend.value.push(0);
              sumLate.value.push(0);
              finalAttend.value.push(0);
            }

            let temp = [];

            if (data.rows > 0) {
              for (let i = 0; i < data.rows; i++) {
                eval("student" + (i + 1) + "Name.value = d[" + i + "].name");
              }
              moment.updateLocale("ko", {
                weekdaysShort: ["일", "월", "화", "수", "목", "금", "토"],
              });

              
                for (let i = 0; i < onlineTotal.value; i++) {
                  const aa = {};
                  aa.lessonName = d2[i].name;
                  aa.lessonNo = d2[i].lessonNo;
                  aa.lessonDate = moment(d2[i].lessonDate).format("M/D");
                  aa.lessonWeek = moment(d2[i].lessonDate).format("ddd");
                  aa.sumAttend = 0;
                  aa.sumAbscent = 0;
                  aa.remark = d3[i].remark;
                  for (let z = 0; z < data.rows; z++) {
                    eval(
                      "aa.student" +
                        (z + 1) +
                        "Attend = d[" +
                        z +
                        "].onlineLesson" +
                        (i + 1) +
                        ".attend"
                    );
                    if (i == 0 && eval("aa.student" + (z + 1) + "Attend !=0")) {
                      section1Attend.value[z]++;
                    }
                    if (query.value.grade == "경전대학") {
                      if (
                        section1.includes(i) &&
                        eval("aa.student" + (z + 1) + "Attend !=0")
                      ) {
                        section2Attend.value[z]++;
                      }
                      
                      if (
                        section2.includes(i) &&
                        eval("aa.student" + (z + 1) + "Attend !=0")
                      ) {
                        section3Attend.value[z]++;
                      }
                      if (
                        section3.includes(i) &&
                        eval("aa.student" + (z + 1) + "Attend !=0")
                      ) {
                        section4Attend.value[z]++;
                      }
                    } 
                    if (eval("aa.student" + (z + 1) + "Attend !=0")) {
                      sumAttend.value[z]++;
                    }
                    if (
                      eval(
                        "aa.student" +
                          (z + 1) +
                          "Attend == -1 || aa.student" +
                          (z + 1) +
                          "Attend == -2"
                      )
                    ) {
                      sumLate.value[z]++;
                    }
                    if (eval("aa.student" + (z + 1) + "Attend != 0")) {
                      aa.sumAttend = aa.sumAttend + 1;
                    } else {
                      aa.sumAbscent = aa.sumAbscent + 1;
                    }
                  }
                  temp.push(aa);
                }
            
                for (let i = 0; i < talkTotal.value; i++) {
                  const aa = {};
                  aa.lessonName = d4[i].title;
                  aa.lessonNo = d4[i].lessonNo + onlineTotal.value;
                  // aa.remark = d4[i].remark; 

                  aa.lessonDate = moment(d4[i].lessonDate).format("M/D");
                  aa.lessonWeek = moment(d4[i].lessonDate).format("ddd");

                  aa.sumAttend = 0;
                  aa.sumAbscent = 0;
                  for (let z = 0; z < data.rows; z++) {
                    eval(
                      "aa.student" +
                        (z + 1) +
                        "Attend = d[" +
                        z +
                        "].talkLesson" +
                        (i + 1) +
                        ".attend"
                    );
                    if (eval("aa.student" + (z + 1) + "Attend !=0")) {
                      talkAttend.value[z]++;
                    }
                    if (eval("aa.student" + (z + 1) + "Attend !=0")) {
                      sumAttend.value[z]++;
                    }
                    if (
                      eval(
                        "aa.student" +
                          (z + 1) +
                          "Attend == -1 || aa.student" +
                          (z + 1) +
                          "Attend == -2"
                      )
                    ) {
                      sumLate.value[z]++;
                    }
                    if (eval("aa.student" + (z + 1) + "Attend != 0")) {
                      aa.sumAttend = aa.sumAttend + 1;
                    } else {
                      aa.sumAbscent = aa.sumAbscent + 1;
                    }
                  }
                  temp.push(aa);
                }
            
                for (let i = 0; i < offlineTotal.value; i++) {
                  const aa = {};
                  aa.lessonName = d5[i].title;
                  aa.lessonNo = d5[i].lessonNo + onlineTotal.value + talkTotal.value;
                  aa.lessonDate = d5[i].start.substr(5, 10) + "~" + d5[i].end.substr(5, 10);                 
                  aa.sumAttend = 0;
                  aa.sumAbscent = 0;
                  for (let z = 0; z < data.rows; z++) {
                    eval(
                      "aa.student" +
                        (z + 1) +
                        "Attend = d[" +
                        z +
                        "].offlineLesson" +
                        (i + 1) +
                        ".attend"
                    );
                    if (eval("aa.student" + (z + 1) + "Attend !=0")) {
                      offlineAttend.value[z]++;
                    }
                    if (eval("aa.student" + (z + 1) + "Attend !=0")) {
                      sumAttend.value[z]++;
                    }
                    if (
                      eval(
                        "aa.student" +
                          (z + 1) +
                          "Attend == -1 || aa.student" +
                          (z + 1) +
                          "Attend == -2"
                      )
                    ) {
                      sumLate.value[z]++;
                    }
                    if (eval("aa.student" + (z + 1) + "Attend != 0")) {
                      aa.sumAttend = aa.sumAttend + 1;
                    } else {
                      aa.sumAbscent = aa.sumAbscent + 1;
                    }
                  }
                  temp.push(aa);
                } 

              for (let z = 0; z < data.rows; z++) {
                eval(
                  "finalAttend.value[" +
                    z +
                    "] = sumAttend.value[" +
                    z +
                    "] - parseInt(sumLate.value[" +
                    z +
                    "]/3)"
                );
              }
            }
            tableData2.value = temp;
          });
        });
       });
      });
     });
    };
    const roomStatics = () => {
      tableType.value = "roomStatic";
      const url3 = `manage/class2/talk?grade=${query.value.grade}&year=${token.year}&semester=${token.semester}&special=special`;
          api.get(url3).then((response4) => {
            if (!api.isSuccess(response4)) {
              return;
            }
            const data4 = response4.data;
            const d4 = data4.data; 
        const url4 = `manage/class2/weekend?grade=${query.value.grade}&year=${token.year}&semester=${token.semester}&special=special`;
        api.get(url4).then((response5) => {
          if (!api.isSuccess(response5)) {
            return;
          }
          const data5 = response5.data;
          const d5 = data5.data;

      const url = `manage/class2/lesson?grade=${query.value.grade}&lessonName=${query.value.lessonName}&year=${token.year}&semester=${token.semester}&special=special`;
      api.get(url).then((response2) => {
        if (!api.isSuccess(response2)) {
          return;
        }
        const data2 = response2.data;
        const d2 = data2.data;
        let lessonNo = 0;
        if (query.value.lessonName.includes("즉문")) {
          lessonNo = d4[0].lessonNo;
        } else if (query.value.lessonName.includes("실천활동")) {
          lessonNo = d5[0].lessonNo;
        } else {
          lessonNo = d2[0].lessonNo;
        }
        const url = `manage/class2/attend112?grade=${query.value.grade}&room=${query.value.room}&local=${query.value.local}`;
        api.get(url).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const data = response.data;
          const d = data.data;
          const temp = [];
          let data2 = {};
          data2.total = 0;
          data2.attend = 0;
          data2.late = 0;
          data2.early = 0;
          data2.abscent = 0;
          data2.team = "합계";
          let init = 0;

          for (const row of d) {
            for (let i = 1; i < 11; i++)
              if (row.team == i) {
                if (row.team != 1) {
                  if (init == i - 2) {
                    temp.push(data2);
                    init = i - 1;
                    data2 = {};
                    data2.total = 0;
                    data2.attend = 0;
                    data2.late = 0;
                    data2.early = 0;
                    data2.abscent = 0;
                    data2.team = row.team;
                  }
                }
                data2.team = row.team;
                if (query.value.lessonName.includes("즉문즉설")) {
                  if (
                    eval("row.talkLesson" + lessonNo + ".attend == 1")
                  ) {
                    data2.attend++;
                  } else if (
                    eval("row.talkLesson" + lessonNo + ".attend == -1")
                  ) {
                    data2.late++;
                  } else if (
                    eval("row.talkLesson" + lessonNo + ".attend == -2")
                  ) {
                    data2.early++;
                  } else if (
                    eval("row.talkLesson" + lessonNo + ".attend == 0")
                  ) {
                    data2.abscent++;
                  }
                  data2.total++;
                } else if (query.value.lessonName.includes("실천활동")) {
                  if (
                    eval("row.offlineLesson" + lessonNo + ".attend == 1")
                  ) {
                    data2.attend++;
                  } else if (
                    eval("row.offlineLesson" + lessonNo + ".attend == -1")
                  ) {
                    data2.late++;
                  } else if (
                    eval("row.offlineLesson" + lessonNo + ".attend == -2")
                  ) {
                    data2.early++;
                  } else if (
                    eval("row.offlineLesson" + lessonNo + ".attend == 0")
                  ) {
                    data2.abscent++;
                  }
                  data2.total++;
                } else {
                  if (
                    eval("row.onlineLesson" + lessonNo + ".attend == 1")
                  ) {
                    data2.attend++;
                  } else if (
                    eval("row.onlineLesson" +  lessonNo + ".attend == -1")
                  ) {
                    data2.late++;
                  } else if (
                    eval("row.onlineLesson" +  lessonNo + ".attend == -2")
                  ) {
                    data2.early++;
                  } else if (
                    eval("row.onlineLesson" + lessonNo + ".attend == 0")
                  ) {
                    data2.abscent++;
                  }
                  data2.total++;
                }
              }
          }
          temp.push(data2);
          tableData2.value = temp;
          console.log("aa: " + JSON.stringify(tableData2));
        });
      });
      });
    });
    };
    const google = () => {
      const path = `manage/class2/attendDownload4?grade=${query.value.grade}&lessonType=${query.value.lessonType}&lessonName=${query.value.lessonName}`;
      api.download(path);
      alert("구글로 보냈습니다.");
      if (query.value.grade === "불교대학") {
        window.open(
          "https://docs.google.com/spreadsheets/d/1FbroccHQCSxOaXfnoOf_kBIILqYOjmos5YuA9kNY9mw/edit?usp=share_link"
        );
      } else if (query.value.grade === "경전대학") {
        window.open(
          "https://docs.google.com/spreadsheets/d/11pL_TwiRK1afzM1XXHkN8Cj4Zkp2d-V-E9eso6J6kdE/edit?usp=share_link"
        );
      }
    };
    const excel = () => {
      const path = `manage/class2/meetingExcelDownload?grade=${query.value.grade}&lessonType=${query.value.lessonType}&lessonName=${query.value.lessonName}`;
      api.download(path);
    };

    return {
      header,
      team2,
      section1Attend,
      talkAttend,
      section4Attend,
      section5Attend,
      offlineAttend,
      section3Attend,
      finalAttend,
      sumAttend,
      sumLateAttend,
      sumLate,
      section2Attend,
      student1Name,
      student2Name,
      student3Name,
      student4Name,
      student5Name,
      student6Name,
      student7Name,
      student8Name,
      student9Name,
      student10Name,
      student11Name,
      team1LeaderName,
      team2LeaderName,
      team3LeaderName,
      team4LeaderName,
      team5LeaderName,
      team6LeaderName,
      team7LeaderName,
      team8LeaderName,
      team9LeaderName,
      team10LeaderName,
      team11LeaderName,
      page2,
      local,
      room,
      classLeader,
      newData,
      bsection,
      ksection,
      BLESSON_NAMES3,
      KLESSON_NAMES3,
      LOCAL_NAMES_B,
      ROOM_NUMBER2,
      query,
      scrollQuery,
      getDataFromApi,
      attend,
      notice2,
      discuss2,
      decision2,
      special2,
      gradeChange,
      excel,
      teamAssistEdit,
      teamLeaderEdit,
      previewBody,
      previewTitle,
      htmlEditorKey,
      tableType,
      goMeet,
      tableData,
      tableData2,
      tableData3,
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      total2, // 총 레코드 수
      verboseSearchOptionManager,
      verboseSearchOptionSemester,
      verboseSearchOptionBranchMO,
      verboseSearchOptionBranch,
      verboseSearchOptionMaster,
      verboseSearchOptionClassLeader,
      verboseSearchOptionManagerAndMentor,
      modalActive,
      modalActive2,
      noticeModalActive,
      specialModalActive,
      decisionModalActive,
      discussModalActive,
      teamLeaderModalActive,
      teamLeaderModalActive2,
      closedModal,
      goShare,
      handleEdit,
      handleEdit2,
      handleEditTeamAssist,
      handleEditTeamLeader,
      noticeAll,
      noticeAllEdit,
      noticeAllModalActive,
      onAdd,
      add,
      onEdit,
      onEdit2,
      onEdit3,
      onNoticeEdit,
      onSpecialEdit,
      onDiscussEdit,
      onDecisionEdit,
      handleDelete,
      roomStatics,
      google,
      showPreviewModal,
      offlineTotal,
      onlineTotal,
      sumTotal,
      talkTotal,
      section1Total,
      section2Total,
      section3Total,
      section1Name,
      section2Name,
      section3Name,
    };
  },
  computed: {
    ...mapState(["isRTL", "isNavFixed", "darkMode"]),
  },
  mounted() {
    const modal = document.querySelectorAll(".modal-inner");
    const modal2 = document.querySelectorAll(".modal-content");
    if (this.$store.state.darkMode) {
      for (var i = 0; i < modal.length; i++) {
        modal[i].style.backgroundColor = "#172b4d";
        modal2[i].style.backgroundColor = "#172b4d";
      }
    }
  },
};
</script>
<style scoped>
.fixed {
  position: sticky;
  top: 0px;
}
.fixed th {
  background-color: rgba(248, 251, 249, 1);
}
.bgcolor {
  background-color: rgba(248, 251, 249, 1);
}

#titlefix {
  position: sticky;
  top: 0px;
}
.clickable {
  cursor: pointer;
}
.modal-content {
  display: flex;
  flex-direction: column;
}

table {
  width: auto;
  text-align: left;
}
table th {
  padding: 5px;
  border-bottom: 0.5px solid rgb(237, 237, 237);
  text-align: center;
  background-color: rgba(184, 219, 196, 0.1);
}
table tr {
  border-bottom-style: solid;
  border-color: #efefef;
  border-width: 0.2px;
}
table tr:nth-of-type(even) {
  background-color: rgba(226, 226, 245, 0.1);
}
table td {
  padding: 5px;
  text-align: center;
  word-break: break-all;
}
.test {
  max-width: 500px;
  text-align: left;
  word-break: break-all;
}
.test2 {
  text-align: right;
}
.test3 {
  text-align: center;
}
.temp {
  width: auto;
}
.fss {
  font-size: 0.65rem;
}
.modal {
  align-items: unset;
}
</style>

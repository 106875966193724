<!--
=========================================================
* Vue Argon Dashboard 2 PRO - v3.0.1
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->

<template>
  <div
    v-show="layout === 'landing'"
    class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
  ></div>
  <sidenav v-if="showSidenav" />
  <main class="main-content position-relative max-height-vh-100 h-100">
    <navbar v-if="showNavbar" />
    <router-view />
    <app-footer v-show="showFooter" />
    <configurator
      :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
    />
  </main>
</template>
<script>
import Sidenav from "@/views/Layout/Sidenav";
import Configurator from "@/views/Layout/Configurator.vue";
import Navbar from "@/views/Layout/Navbars/Navbar.vue";
import AppFooter from "@/views/Layout/Footer.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import * as api from "@/api";
import { activateDarkMode, deactivateDarkMode } from "@/assets/js/dark-mode";

export default {
  name: "App",
  components: {
    Sidenav,
    Configurator,
    Navbar,
    AppFooter,
  },
  computed: {
    ...mapState([
      "layout",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
      "sidebarType",
    ]),
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    ...mapMutations([
      "navbarMinimize",
      "navbarFixed",
      "setSidebarType",
      "toggleConfigurator",
    ]),
    ...mapActions(["toggleSidebarColor"]),
    sidebarColor(color = "success") {
      document.querySelector("#sidenav-main").setAttribute("data-color", color);
    },
    sidebar(type) {
      this.setSidebarType(type);
    },
    getDataFromApi() {
      const token = api.Auth.tokenContent;
      if (token.email) {
        const url = `manage/user/user2/${token.email}`;
        api.get(url).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const data = response.data.data;
          if (data.color) {
            this.sidebarColor(data.color);
          }
          if (data.darkMode === true) {
            this.$store.state.darkMode = true;
            this.sidebar("bg-default");
            activateDarkMode();
          } else {
            this.$store.state.darkMode = false;
            this.sidebar("bg-white");
            deactivateDarkMode();
          }
          if (data.bgColor) {
            this.sidebar(data.bgColor);
          }
        });
      }
    },
  },
};
</script>

import { createRouter, createWebHashHistory } from "vue-router";
import SigninBasic from "../views/auth/signin/Basic.vue";
import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";
import Team from "../views/LiveClass/Team/List.vue";
import Room from "../views/LiveClass/Room/List.vue";
import Student from "../views/LiveClass/Student/List2.vue";
import Library from "../views/LiveClass/Library/List.vue";
import NoticeStudent from "../views/LiveClass/NoticeStudent/List.vue";
import NoticeManager from "../views/LiveClass/NoticeManager/List2.vue";
import Lesson from "../views/LiveClass/Lesson/List.vue";
import LessonIrregular from "../views/LiveClass/LessonIrregular/List.vue";
import Lecture from "../views/LiveClass/Lecture/List.vue";
import Activist from "../views/LiveClass/Activist/List.vue";
import Thousand from "../views/LiveClass/Thousand/List.vue";
import Weekend from "../views/LiveClass/Weekend/List.vue";
import Talk from "../views/LiveClass/Talk/List.vue";
import PracticeRetro from "../views/LiveClass/PracticeRetro/List8.vue";
import SharePractice from "../views/LiveClass/SharePractice/List2.vue";
import Question2 from "../views/LiveClass/Question2/List.vue";
import Question from "../views/LiveClass/Question/List.vue";
import Frequency from "../views/LiveClass/Frequency/List.vue";
import MyInformation from "../views/Service/MyInfo.vue";
import Main from "../views/Service/Main.vue";
import Online from "../views/LiveClass/Online/List3.vue";
import Offline from "../views/LiveClass/Offline/List2.vue";
import TalkClass from "../views/LiveClass/TalkClass/List2.vue";
import Zoom from "../views/LiveClass/Zoom/List.vue";
import MenuLink from "../views/LiveClass/MenuLink/List.vue";
import Proposal from "../views/LiveClass/Proposal/List.vue";
import Comment from "../views/LiveClass/Proposal/List2.vue";
import MeetingAttend from "../views/LiveClass/MeetingAttend/List2.vue";
import AttendStats from "../views/LiveClass/AttendStats/List2.vue";
import AttendStatsTotal from "../views/LiveClass/AttendStatsTotal/List2.vue";
import Subject from "../views/LiveClass/Subject/List.vue";
import { Auth } from "@/api";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/home/main",
  },
  {
    path: "/manager/team",
    name: "Team",
    component: Team,
    meta: { loginRequired: true },
  },
  {
    path: "/attend/online",
    name: "Online",
    component: Online,
    meta: { loginRequired: true },
  },
  {
    path: "/attend/offline",
    name: "Offline",
    component: Offline,
    meta: { loginRequired: true },
  },
  {
    path: "/attend/talkClass",
    name: "TalkClass",
    component: TalkClass,
    meta: { loginRequired: true },
  },
  {
    path: "/attend/attendStats",
    name: "AttendStats",
    component: AttendStats,
    meta: { loginRequired: true },
  },
  {
    path: "/attend/subject",
    name: "Subject",
    component: Subject,
    meta: { loginRequired: true },
  },
  {
    path: "/attend/attendStatsTotal",
    name: "AttendStatsTotal",
    component: AttendStatsTotal,
    meta: { loginRequired: true },
  },
  {
    path: "/attend/meetingAttend",
    name: "MeetingAttend",
    component: MeetingAttend,
    meta: { loginRequired: true },
  },
  {
    path: "/home/main",
    name: "Main",
    component: Main,
    meta: { loginRequired: true },
  },
  {
    path: "/manager/myInfo",
    name: "MyInformation",
    component: MyInformation,
    meta: { loginRequired: true },
  },
  {
    path: "/manager/frequency",
    name: "Frequency",
    component: Frequency,
    meta: { loginRequired: true },
  },
  {
    path: "/manager/proposal",
    name: "Proposal",
    component: Proposal,
    meta: { loginRequired: true },
  },
  {
    path: "/student/zoom",
    name: "Zoom",
    component: Zoom,
    meta: { loginRequired: true },
  },
  {
    path: "/manager/comment",
    name: "Comment",
    component: Comment,
    meta: { loginRequired: true },
  },
  {
    path: "/manager/question2",
    name: "Question2",
    component: Question2,
    meta: { loginRequired: true },
  },
  {
    path: "/manager/question",
    name: "Question",
    component: Question,
    meta: { loginRequired: true },
  },
  {
    path: "/manager/sharePractice",
    name: "SharePractice",
    component: SharePractice,
    meta: { loginRequired: true },
  },
  {
    path: "/manager/practiceRetro",
    name: "PracticeRetro",
    component: PracticeRetro,
    meta: { loginRequired: true },
  },
  {
    path: "/student/weekend",
    name: "Weekend",
    component: Weekend,
    meta: { loginRequired: true },
  },
  {
    path: "/student/talk",
    name: "Talk",
    component: Talk,
    meta: { loginRequired: true },
  },
  {
    path: "/student/thousand",
    name: "Thousand",
    component: Thousand,
    meta: { loginRequired: true },
  },
  {
    path: "/manager/activist",
    name: "Activist",
    component: Activist,
    meta: { loginRequired: true },
  },
  {
    path: "/student/lecture",
    name: "Lecture",
    component: Lecture,
    meta: { loginRequired: true },
  },
  {
    path: "/student/lesson",
    name: "Lesson",
    component: Lesson,
    meta: { loginRequired: true },
  },
  {
    path: "/student/lessonIrregular",
    name: "LessonIrregular",
    component: LessonIrregular,
    meta: { loginRequired: true },
  },
  {
    path: "/student/noticeStudent",
    name: "NoticeStudent",
    component: NoticeStudent,
    meta: { loginRequired: true },
  },
  {
    path: "/manager/noticeManager",
    name: "NoticeManager",
    component: NoticeManager,
    meta: { loginRequired: true },
  },
  {
    path: "/manager/room",
    name: "Room",
    component: Room,
    meta: { loginRequired: true },
  },
  {
    path: "/manager/student",
    name: "Student",
    component: Student,
    meta: { loginRequired: true },
  },
  {
    path: "/student/library",
    name: "Library",
    component: Library,
    meta: { loginRequired: true },
  },
  {
    path: "/student/menuLink",
    name: "MenuLink",
    component: MenuLink,
    meta: { loginRequired: true },
  },
  {
    path: "/login",
    name: "Signin Basic",
    component: SigninBasic,
  },
  {
    path: "/authentication/error/error404",
    name: "Error Error404",
    component: Error404,
  },
  {
    path: "/authentication/error/error500",
    name: "Error Error500",
    component: Error500,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from_, next) => {
  if (to.meta.loginRequired && !Auth.isLoggedIn) {
    sessionStorage.setItem("fullPath", to.fullPath);
    next({ name: "Signin Basic" });
  } else {
    next();
  }
});

export default router;

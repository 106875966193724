<!-- 조별 주소 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">줌 주소</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <!-- <route-bread-crumb></route-bread-crumb> -->
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col"></div>
                <div v-if="inputPossible" class="col text-right">
                  <!-- New Button -->
                  <base-button type="primary" class="btn-sm" @click="handleAdd"
                    >+주소추가</base-button
                  >
                  <!-- End New Button -->
                </div>
              </div>
            </div>
            <!-- End Search, New Button -->
            <!-- modal -->
            <edit-modal
              ref="editModal"
              @add-done="onAddDone"
              @edit-done="onEditDone"
            />
            <!-- List -->

            <el-table
              class="table-responsive table"
              :data="tableData"
              :default-sort="{ prop: 'local', order: 'descending' }"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="학 년"
                sortable
                min-width="120px"
                prop="grade"
              >
              </el-table-column>
              <el-table-column
                label="학 기"
                sortable
                min-width="100px"
                prop="semester"
              >
              </el-table-column>
              <el-table-column label="줌 주소" sortable min-width="290px">
                <template #default="{ row }">
                  <div class="font-weight-600">
                    <a @click="goMeet(row.url)">{{ row.url }}</a>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="inputPossible"
                v-slot="{ row }"
                label="ACTION"
                min-width="130px"
              >
                <div class="d-flex">
                  <base-button
                    class="edit me-1"
                    type="warning"
                    size="sm"
                    icon
                    @click.stop="handleEdit(row)"
                  >
                    <i class="text-white fa fa-pen" />
                  </base-button>
                  <base-button
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                    @click.stop="handleDelete(row)"
                  >
                    <i class="text-white fas fa-trash-alt"></i>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
            <!-- End List -->
            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                :rows-per-page="perPage"
                :total="total"
                @page-change="getDataFromApi"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
// import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { ElTable, ElTableColumn } from "element-plus";
import EditModal from "./Board/EditModal.vue";
import * as api from "@/api";
import { ROOM_NUMBER, TEAM_NUMBER, LOCAL_NAMES_B } from "@/consts/class";
export default {
  // 컴포넌트 초기화
  components: {
    // RouteBreadCrumb,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    EditModal,
  },
  // 데이터 객체 추가
  data() {
    return {
      ROOM_NUMBER,
      TEAM_NUMBER,
      LOCAL_NAMES_B,
      tableData: [], // 레코드셋
      query: {
        // 검색 쿼리
        local: "",
        team: "",
        room: "",
      },
      newData: {
        // 새 등록 데이터
        id: 0,
        local: "",
        url: "",
        team: "",
        room: "",
      },
      perPage: 20, // 페이지당 레코드 수
      total: 0, // 총 레코드 수
      inputPossible: false,
    };
  },
  created() {
    // 페이지 생성 시 호출
    this.getDataFromApi();
    const token = api.Auth.tokenContent;
    this.inputPossible =
      token.role.includes("manager") || token.role.includes("master");
  },
  // 함수 추가
  methods: {
    // api 호출
    getDataFromApi(page = 1) {
      const token = api.Auth.tokenContent;

      //쿼리조건 추가
      for (const field of ["local", "team", "room"]) {
        if (this.query[field] === "전체") {
          this.query[field] = "";
        }
      }

      const url = `manage/class2/zoom?bound=${this.perPage}&page=${page}&year=${token.year}&semester=${token.semester}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        this.tableData = d;
        this.total = data.total;
      });
    },
    goMeet(url) {
      window.open(url);
    },
    // 등록 모달
    handleAdd() {
      this.$refs.editModal.handleAdd();
    },
    // 수정 모달
    handleEdit(row) {
      this.$refs.editModal.handleEdit(row);
    },
    // 등록 완료
    onAddDone() {
      this.query.value = "";
      this.getDataFromApi(1);
    },
    // 수정 완료
    onEditDone(row, data) {
      row.local = data.local;
      this.tableData = this.tableData.slice();
      this.getDataFromApi(1);
    },
    // 삭제하기
    handleDelete(row) {
      if (confirm("삭제하시겠습니까?")) {
        api.delete_(`manage/class2/zoom/${row.id}`).then(() => {
          this.tableData = this.tableData.filter((r) => r.id !== row.id);
        });
      }
    },
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
</style>

<template>
  <span :class="attendStyle">{{ attendText }}</span>
</template>

<script>
import { ATTEND_TYPES, ATTEND_TYPE_COLORS } from "@/consts/attend";

export default {
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    attendText: function () {
      return ATTEND_TYPES[this.value];
    },
    attendStyle: function () {
      return ATTEND_TYPE_COLORS[this.value];
    },
  },
};
</script>

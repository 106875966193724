<!-- 수업 자료실 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h3 text-white d-inline-block mb-0">자주 묻는 질문</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col-sm-2">
                  <input
                    v-model="query.title"
                    type="text"
                    class="form-control col"
                    placeholder="질문제목"
                    @keyup.enter.prevent="getDataFromApi(1)"
                  />
                </div>
                <div class="col-sm-2">
                  <input
                    v-model="query.question"
                    type="text"
                    class="form-control col"
                    placeholder="질문내용"
                    @keyup.enter.prevent="getDataFromApi(1)"
                  />
                </div>
                <div class="col-sm-2">
                  <input
                    v-model="query.answer"
                    type="text"
                    class="form-control col"
                    placeholder="답변내용"
                    @keyup.enter.prevent="getDataFromApi(1)"
                  />
                </div>
                <div class="col">
                  <base-button
                    class="btn-sm-2 mt-3"
                    type="secondary"
                    @click="getDataFromApi(1)"
                    >검색</base-button
                  >
                </div>
                <div v-if="verboseSearchOptionMaster" class="col text-end mt-3">
                  <base-button type="primary" class="btn-sm" @click="handleAdd"
                    >+추가</base-button
                  >
                </div>
              </div>
            </div>
            <!-- t: top, b: bottom, s: left, e: right x: left & right y: top & bottom -->
            <div class="row" style="position: relative; height: ">
              <div class="col-sm-1" style="position: absolute; bottom: 0px">
                <preset-select
                  v-model="query.bound"
                  size="sm"
                  :choices="[
                    ['5', '5'],
                    ['10', '10'],
                    ['15', '15'],
                    ['20', '20'],
                  ]"
                  @change="getDataFromApi(1)"
                />
              </div>
              <div
                class="col"
                style="position: absolute; bottom: 0px; left: 120px"
              >
                total: {{ total }}
              </div>
            </div>
            <!-- Preview Modal -->
            <Modal :modal-active="showPreviewModal" @close="closedModal">
              <div class="modal-content">
                <h5 class="ms-4 mt-3">
                  <span class="col-sm-2"> 질문 제목: </span>
                  <span class="col">
                    {{ previewTitle }}
                  </span>
                </h5>

                <div class="container">
                  <div class="row">
                    <span class="col-sm-2 ps-1 pe-0 ms-2"> 질문내용: </span>
                    <span class="col ms-0 ps-0">
                      <span
                        v-dompurify-html="previewQuesion"
                        class="text-left ms-0 ps-0"
                      ></span>
                    </span>
                  </div>
                </div>
                <div class="container">
                  <div class="row">
                    <span class="col-sm-2 ps-1 pe-0 ms-2"> 답변내용: </span>
                    <span class="col ms-0 ps-0">
                      <span
                        v-dompurify-html="previewAnswer"
                        class="text-left ms-0 ps-0"
                      ></span>
                    </span>
                  </div>
                </div>

                <base-button
                  type="link"
                  class="ml-auto"
                  @click="showPreviewModal = false"
                  >Close</base-button
                >
              </div>
            </Modal>
            <Modal :modal-active="modalActive" @close="closedModal">
              <div class="modal-content">
                <h2>자주묻는질문</h2>
                <card type="secondary" body-classes="px-lg-5">
                  <form role="form">
                    <div class="row-sm-3 mt-3">
                      <preset-radio
                        v-model="newData.class"
                        :choices="[
                          ['전체', '전체'],
                          ['기본반', '기본반'],
                          ['생방송반', '생방송반'],
                        ]"
                      />
                    </div>
                    <div class="row-sm-3 mt-3">
                      <preset-radio
                        v-model="newData.grade"
                        :choices="[
                          ['전체', '전체'],
                          ['불교대학', '불교대학'],
                          ['경전대학', '경전대학'],
                        ]"
                      />
                    </div>
                    <div class="row">&nbsp;</div>
                    <base-input
                      v-model="newData.title"
                      type="text"
                      label="질문제목"
                      name="title"
                      size="120"
                    />

                    <div class="row">&nbsp;</div>
                    <div class="row">
                      <label class="form-control-label">질문내용</label>
                    </div>
                    <div :key="htmlEditorKey">
                      <html-editor v-model="newData.question" />
                    </div>
                    <div class="row">&nbsp;</div>
                    <div class="row">
                      <label class="form-control-label">답변내용</label>
                    </div>
                    <div :key="htmlEditorKey">
                      <html-editor v-model="newData.answer" />
                    </div>

                    <div class="text-center">
                      <base-button
                        v-if="add"
                        type="primary"
                        class="my-4"
                        @click="onAdd(newData)"
                        >등록 하기</base-button
                      >
                      <base-button
                        v-else
                        type="primary"
                        class="my-4"
                        @click="onEdit(newData)"
                        >수정 하기</base-button
                      >
                      <base-button
                        type="secondary"
                        class="my-4 ms-2"
                        @click="closedModal"
                        >닫 기</base-button
                      >
                    </div>
                  </form>
                </card>
              </div>
            </Modal>
            <div class="table-responsive">
              <table class="table table-flush">
                <thead>
                  <th v-for="item in header" :key="item.index">{{ item }}</th>
                </thead>
                <tbody>
                  <tr v-for="row in tableData" :key="row.index">
                    <td>{{ row.no }}</td>
                    <td max-width="300px" style="white-space: normal">
                      <span class="clickable" @click="showPreview(row)">
                        <div
                          class="font-weight-600"
                          style="color: cornflowerblue"
                        >
                          {{ row.title }}
                        </div>
                      </span>
                    </td>
                    <td>{{ row.class }}</td>
                    <td>{{ row.grade }}</td>
                    <td>{{ row.createdTime }}</td>
                    <td>{{ row.name }}</td>
                    <td width="10%">
                      <div class="d-flex pe-4">
                        <base-button
                          v-if="verboseSearchOptionBranch"
                          class="edit me-1"
                          type="warning"
                          size="sm"
                          icon
                          @click.stop="handleEdit(row)"
                        >
                          <i class="text-white fa fa-pen" />
                        </base-button>
                        <base-button
                          v-if="verboseSearchOptionManager"
                          class="remove btn-link"
                          type="danger"
                          size="sm"
                          icon
                          @click.stop="handleDelete(row)"
                        >
                          <i class="text-white ni ni-fat-remove" />
                        </base-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                :rows-per-page="perPage"
                :total="total"
                :model-value="page2"
                @page-change="getDataFromApi"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import * as api from "@/api";
import { ref } from "vue";
import PresetSelect from "@/components/Inputs/PresetSelect";
import PresetRadio from "@/components/Inputs/PresetRadio";
import Modal from "./Board/Modal";
import moment from "moment";

export default {
  // 컴포넌트 초기화
  components: {
    PresetSelect,
    PresetRadio,
    Modal,
    HtmlEditor,
  },

  setup() {
    const tableData = ref([]);
    const newData = ref({});
    const perPage = ref(20); // 페이지당 레코드 수
    let page2 = ref(1);
    const total = ref(0); // 총 레코드 수
    const query = ref({
      question: "",
      title: "",
      answer: "",
      bound: 20,
    });
    let previewTitle = ref("");
    let previewQuesion = ref("");
    let previewAnswer = ref("");
    let showPreviewModal = ref(false);
    let htmlEditorKey = ref(0);
    let verboseSearchOptionManager = ref(false);
    let verboseSearchOptionBranch = ref(false);
    let verboseSearchOptionMaster = ref(false);
    let modalActive = ref(false);
    const add = ref(false);
    const header = ref([
      "No",
      "질문제목",
      "수업종류",
      "학년",
      "날짜",
      "작성자",
      "Action",
    ]);

    const token = api.Auth.tokenContent;
    query.value.bound = token.listType;
    perPage.value = query.value.bound;

    const getDataFromApi = (page = 1) => {
      page2.value = page;
      perPage.value = query.value.bound;
      const url = `manage/class2/frequency?bound=${query.value.bound}
   &title=${query.value.title}&question=${query.value.question}&page=${page}&answer=${query.value.answer}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        if (data.rows > 0) {
          for (const row of d) {
            row.createdTime = moment(row.createdTime).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }
        }
        tableData.value = d;
        total.value = data.total;
      });
    };
    getDataFromApi(1);
    const created = () => {
      // 페이지 생성 시 호출
      const token = api.Auth.tokenContent;
      verboseSearchOptionManager.value =
        token.role.includes("manager") || token.role.includes("master");
      verboseSearchOptionBranch.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("branch");
      verboseSearchOptionMaster.value = token.role.includes("master");
    };
    created();

    const showPreview = (row) => {
      previewTitle.value = row.title;
      previewQuesion.value = row.question;
      previewAnswer.value = row.answer;
      showPreviewModal.value = true;
    };

    const goMeet = (url) => {
      window.open(url);
    };
    const closedModal = () => {
      modalActive.value = false;
      showPreviewModal.value = false;
    };
    // 등록 모달
    const handleAdd = () => {
      modalActive.value = true;
      newData.value.title = "";
      newData.value.answer = "";
      newData.value.question = "";
      newData.value.grade = "";
      newData.value.class = "";
      htmlEditorKey.value++;
      add.value = true;
    };
    // 수정 모달
    const handleEdit = (row) => {
      api.get(`manage/class2/frequency/${row.id}`).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        htmlEditorKey.value++;
        modalActive.value = !modalActive.value;
        newData.value = response.data.data;
        add.value = false;
      });
    };
    // 등록
    const onAdd = () => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.post("manage/class2/frequency", { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 수정
    const onEdit = (row) => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.put(`manage/class2/frequency/${row.id}`, { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 삭제하기
    const handleDelete = (row) => {
      if (confirm("삭제하시겠습니까?")) {
        api.delete_(`manage/class2/frequency/${row.id}`).then(() => {
          tableData.value = tableData.value.filter((r) => r.id !== row.id);
        });
      }
    };

    return {
      header,
      page2,
      newData,
      query,
      getDataFromApi,
      previewQuesion,
      previewAnswer,
      previewTitle,
      htmlEditorKey,
      goMeet,
      tableData,
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      verboseSearchOptionManager,
      verboseSearchOptionBranch,
      verboseSearchOptionMaster,
      modalActive,
      closedModal,
      handleEdit,
      onAdd,
      add,
      onEdit,
      handleAdd,
      handleDelete,
      showPreview,
      showPreviewModal,
    };
  },
  mounted() {
    const modal = document.querySelectorAll(".modal-inner");
    const modal2 = document.querySelectorAll(".modal-content");
    if (this.$store.state.darkMode) {
      for (var i = 0; i < modal.length; i++) {
        modal[i].style.backgroundColor = "#172b4d";
        modal2[i].style.backgroundColor = "#172b4d";
      }
    }
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.modal-content {
  display: flex;
  flex-direction: column;
}
table {
  width: 100%;
  text-align: left;
}
table th {
  padding: 12px;
  border-bottom: 2px solid darkgray;
  text-align: center;
  background-color: rgba(184, 219, 196, 0.1);
}
table td {
  padding: 12px;
  text-align: center;
}
table tr:nth-of-type(even) {
  background-color: rgba(226, 226, 245, 0.1);
}
</style>

<template>
  <nav
    id="navbarBlur"
    :class="`${
      !isNavFixed
        ? 'navbar navbar-main navbar-expand-lg px-0 mx-0 border-radius-xl shadow-none'
        : `navbar navbar-main navbar-expand-lg px-0 mx-0 border-radius-xl shadow-none position-sticky ${
            darkMode ? 'bg-default' : 'bg-white'
          } left-auto top-2 z-index-sticky`
    } ${isRTL ? 'top-0 position-sticky z-index-sticky' : ''}`"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-3 pe-0 py-1 container-fluid">
      <breadcrumbs
        :current-page="currentRouteName"
        :current-directory="currentDirectory"
      />
      <div
        class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none"
        :class="isRTL ? 'me-3' : ''"
      >
        <a
          href="#"
          class="p-0 nav-link text-body"
          @click.prevent="navbarMinimize"
        >
          <div class="sidenav-toggler-inner">
            <i
              class="sidenav-toggler-line"
              :class="
                isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
              "
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="
                isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
              "
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="
                isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
              "
            ></i>
          </div>
        </a>
      </div>

      <!-- 상단 드롭다운 메뉴 바로가기 버튼 -->
      <div class="dropdown d-flex justify-content-center ms-5">
        <button
          class="btn btn-light dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          바로가기
        </button>
        <ul
          class="dropdown-menu dropdown-menu-dark position-absolut start-10 end-8"
        >
          <li
            v-if="verboseSearchOptionClassleaderAndMentor"
            class="dropdown-item py-2"
            @click="goMeet1(manualForRoom)"
          >
            사이트 사용 안내<br />(담당용)
          </li>
          <li class="dropdown-item py-2" @click="goMeet1(manualForTeam)">
            사이트 사용 안내<br />(진행자용)
          </li>
          <li class="dropdown-item py-2" @click="goMeet1(buldaeJin)">
            불교대-진행자료홈
          </li>
          <li
            v-if="verboseSearchOptionClassleaderAndMentor"
            class="dropdown-item py-2"
            @click="goMeet1(buldaeDam)"
          >
            불교대-담당홈
          </li>
          <li class="dropdown-item py-2" @click="goMeet1(dhammaJin)">
            경전대-진행자료홈
          </li>
          <li
            v-if="verboseSearchOptionClassleaderAndMentor"
            class="dropdown-item py-2"
            @click="goMeet1(dhammaDam)"
          >
            경전대-담당홈
          </li>
          <li class="dropdown-item py-2" @click="goMeet1(classJungto)">
            수업사이트
          </li>
          <li 
            v-if="verboseSearchOptionClassleaderAndMentor"
            class="dropdown-item py-2" 
            @click="goMeet1(wow)"
          >
            와우 시스템
          </li>
          <li class="dropdown-item py-2" @click="goMeet1(jungto)">정토회 홈페이지</li>
        </ul>
      </div>
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
          <!-- <div class="input-group">
        <span class="input-group-text text-body">
          <i class="fas fa-search" aria-hidden="true"></i>
        </span>
        <input
        type="text"
        class="form-control"
        :placeholder="isRTL ? 'أكتب هنا...' : 'Type here...'"
        />
      </div> -->
        </div>
        <ul class="navbar-nav justify-content-end">
          <li
            class="nav-item d-flex align-items-center me-2"
            :class="
              isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
            "
          >
            <router-link
              :to="{ name: 'MyInformation' }"
              class="px-0 nav-link font-weight-bold"
              :class="
                isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
              "
              target="_self"
            >
              <i class="fa fa-user" :class="isRTL ? 'ms-sm-2' : 'me-sm-1'"></i>
              <span v-if="userName" class="d-sm-inline d-none">{{
                userName
              }}</span>
            </router-link>
          </li>
          <li
            class="nav-item d-flex align-items-center"
            :class="
              isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
            "
          >
            <router-link
              :to="{ name: 'Signin Basic' }"
              class="px-0 nav-link font-weight-bold"
              :class="
                isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
              "
              target="_self"
            >
              <!-- <i
                class="ni ni-user-run"
                :class="isRTL ? 'ms-sm-2' : 'me-sm-1'"
              ></i> -->
              <span class="d-sm-inline d-none">Logout</span>
            </router-link>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              id="iconNavbarSidenav"
              href="#"
              class="p-0 nav-link text-white"
              @click.prevent="navbarMinimize"
            >
              <div class="sidenav-toggler-inner">
                <i
                  :class="`sidenav-toggler-line ${
                    isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
                  }`"
                ></i>
                <i
                  :class="`sidenav-toggler-line ${
                    isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
                  }`"
                ></i>
                <i
                  :class="`sidenav-toggler-line ${
                    isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
                  }`"
                ></i>
              </div>
            </a>
          </li>
          <li class="px-3 nav-item d-flex align-items-center">
            <a class="p-0 nav-link" @click="toggleConfigurator">
              <i
                :class="`cursor-pointer fa fa-cog fixed-plugin-button-nav ${
                  !isNavFixed || darkMode ? 'text-white' : 'opacity-8 text-dark'
                }`"
              ></i>
            </a>
          </li>
          <!-- <li
            class="nav-item dropdown d-flex align-items-center"
            :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <a
              id="dropdownMenuButton"
              href="#"
              :class="`p-0 nav-link ${
                isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
              } ${showMenu ? 'show' : ''}`"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="cursor-pointer fa fa-bell"></i>
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        src="../../assets/img/team-2.jpg"
                        class="avatar avatar-sm me-3"
                        alt="user image"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">New message</span> from
                        Laur
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        13 minutes ago
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        src="../../assets/img/small-logos/logo-spotify.svg"
                        class="avatar avatar-sm bg-gradient-dark me-3"
                        alt="logo spotify"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">New album</span> by
                        Travis Scott
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        1 day
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div
                      class="my-auto avatar avatar-sm bg-gradient-secondary me-3"
                    >
                      <svg
                        width="12px"
                        height="12px"
                        viewBox="0 0 43 36"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <title>credit-card</title>
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            transform="translate(-2169.000000, -745.000000)"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                          >
                            <g transform="translate(1716.000000, 291.000000)">
                              <g transform="translate(453.000000, 454.000000)">
                                <path
                                  class="color-background"
                                  d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z"
                                  opacity="0.593633743"
                                />
                                <path
                                  class="color-background"
                                  d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        Payment successfully completed
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        2 days
                      </p>
                    </div>
                  </div>
                </a>
              </li> -->
          <!-- </ul>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import { Auth } from "@/api";
import * as api from "@/api";

export default {
  name: "Navbar",
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      showMenu: false,
      userName: sessionStorage.getItem("name"),
      buldae: false,
      buldaeJin: "",
      buldaeDam: "",
      dhammaJin: "",
      dhammaDam: "",
      jungto: "https://www.jungto.org/",
      classJungto: "https://class.jungto.org/",
      wow: "https://wow.jungto.org:28319/",
      manualForRoom:
        "https://docs.google.com/presentation/d/1zlykTZxpU-sBkiwTIZs3it-RDP4AlVSNwdY154nZhXk/edit#slide=id.p1",
      manualForTeam:
        "https://docs.google.com/presentation/d/1GtRGDhypRXOFgVhhjzuCztBKOT-OvipIUSDD6vq2sFs/edit#slide=id.p1",
    };
  },
  computed: {
    ...mapState(["isRTL", "isNavFixed", "darkMode"]),
    currentRouteName() {
      return this.$route.name;
    },
    currentDirectory() {
      let dir = this.$route.path.split("/")[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
  },
  created() {
    this.minNav;
    const token = Auth.tokenContent;

    if (token.grade) {
      this.buldae = token.grade.includes("불교대학");
      this.verboseSearchOptionMaster = token.role.includes("master");
      this.verboseSearchOptionManager =
        token.role.includes("manager") || token.role.includes("master");
      this.verboseSearchOptionManager2 =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("mentor") ||
        token.role.includes("observer");
      this.verboseSearchOptionClassleader =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("branch") ||
        token.role.includes("observer") ||
        token.role.includes("class leader");
      this.verboseSearchOptionClassleader2 =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("branch") ||
        token.role.includes("team leader") ||
        token.role.includes("team assist") ||
        token.role.includes("class leader");
      this.verboseSearchOptionMentor =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("mentor");
      this.verboseSearchOptionClassleaderAndMentor =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("branch") ||
        token.role.includes("observer") ||
        token.role.includes("class leader") ||
        token.role.includes("mentor");
      this.verboseSearchOptionWithout = !token.role.includes("mentor");
      this.semesterCode = sessionStorage.getItem("semesterCode");
      this.buldae = token.grade.includes("불교대학");
      this.getDataFromApi();
    }
  },
  beforeUpdate() {
    this.toggleNavigationOnMobile();
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),
    toggleNavigationOnMobile() {
      if (window.innerWidth < 1200) {
        this.navbarMinimize();
      }
    },
    goMeet1(url) {
      window.open(url);
    },
    getDataFromApi() {
      // const token = Auth.tokenContent;

      const url = `manage/class2/menuLink`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;

        // '9월'을 '09', '3월'을 '03'으로 변환하기 위한 함수
        const semesterToMonth = (semester) => {
            return semester.replace('월', '').padStart(2, '0');
        };

        // 데이터를 필터링하고 최신 순으로 정렬하는 함수
        const filterAndSort = (data, grade, belong) => {
            return data
                .filter(element => element.grade === grade && element.belong === belong)
                .sort((a, b) => {
                    const aDate = parseInt(a.year + semesterToMonth(a.semester));
                    const bDate = parseInt(b.year + semesterToMonth(b.semester));
                    return bDate - aDate;  // 최신 데이터가 앞에 오도록 내림차순 정렬
                });
        };

        // 각 필터링된 데이터에 대해 처리
        const sortedBj = filterAndSort(d, "불교대학", "진행자료홈");
        const sortedBd = filterAndSort(d, "불교대학", "담당홈");
        const sortedKj = filterAndSort(d, "경전대학", "진행자료홈");
        const sortedKd = filterAndSort(d, "경전대학", "담당홈");

        // 가장 최근 데이터 가져오기
        this.buldaeJin = sortedBj[0]?.url;        
        this.buldaeDam = sortedBd[0]?.url;        
        this.dhammaJin = sortedKj[0]?.url;        
        this.dhammaDam = sortedKd[0]?.url; 

        // if (data.rows > 0) {
        //   for (const row of d) {
        //     if (row.grade == "불교대학" && row.belong == "진행자료홈") {
        //       this.buldaeJin = row.url;
        //     } else if (row.grade == "불교대학" && row.belong == "담당홈") {
        //       this.buldaeDam = row.url;
        //     } else if (row.grade == "경전대학" && row.belong == "진행자료홈") {
        //       this.dhammaJin = row.url;
        //     } else if (row.grade == "경전대학" && row.belong == "담당홈") {
        //       this.dhammaDam = row.url;
        //     }
        //   }
        // }
      });
    },
  },
};
</script>

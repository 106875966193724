const SUCCESS = 1;
const SUCCESS_BUT_EMPTY = 0;
const ERROR_TOKEN_EXPIRED = -104;
const ERROR_NO_PERMISSION = -109;

// From http://api-server/class/errorcode
const STATUS_CODES = [
  {
    Name: "Success",
    Code: SUCCESS,
    Service: "",
    Category: "Common",
    Description: "Success Result",
  },
  {
    Name: "No Data",
    Code: SUCCESS_BUT_EMPTY,
    Service: "",
    Category: "Common",
    Description: "No Data",
  },
  {
    Name: "Fail",
    Code: -1,
    Service: "",
    Category: "Common",
    Description: "Fail Result",
  },
  {
    Name: "No Change",
    Code: -2,
    Service: "",
    Category: "Common",
    Description: "No Change",
  },
  {
    Name: "Fail load",
    Code: -3,
    Service: "",
    Category: "Common",
    Description: "Fail load",
  },
  {
    Name: "Already exists",
    Code: -8,
    Service: "",
    Category: "Common",
    Description: "Already exists",
  },
  {
    Name: "No File",
    Code: -10,
    Service: "",
    Category: "File",
    Description: "No File",
  },
  {
    Name: "No Sheet",
    Code: -11,
    Service: "",
    Category: "File",
    Description: "No Sheet",
  },
  {
    Name: "Parse Error",
    Code: -12,
    Service: "",
    Category: "File",
    Description: "Parse Error",
  },
  {
    Name: "File Rename Error",
    Code: -13,
    Service: "",
    Category: "File",
    Description: "File Rename Error",
  },
  {
    Name: "Auth Error",
    Code: -100,
    Service: "",
    Category: "Auth",
    Description: "Auth Error",
  },
  {
    Name: "No User",
    Code: -101,
    Service: "",
    Category: "Auth",
    Description: "No User",
  },
  {
    Name: "No Parameters",
    Code: -102,
    Service: "",
    Category: "Auth",
    Description: "No Parameters",
  },
  {
    Name: "No Token",
    Code: -103,
    Service: "",
    Category: "Auth",
    Description: "No Token",
  },
  {
    Name: "Token Expired",
    Code: ERROR_TOKEN_EXPIRED,
    Service: "",
    Category: "Auth",
    Description: "TokenExpiredError: jwt expired",
  },
  {
    Name: "Verify Fail",
    Code: -105,
    Service: "",
    Category: "Auth",
    Description: "Verify Fail",
  },
  {
    Name: "Wrong Code",
    Code: -106,
    Service: "",
    Category: "Auth",
    Description: "Wrong Code",
  },
  {
    Name: "Session Kick out",
    Code: -107,
    Service: "",
    Category: "Auth",
    Description: "Session Kick out",
  },
  {
    Name: "Already exists",
    Code: -108,
    Service: "",
    Category: "Auth",
    Description: "Already exists",
  },
  {
    Name: "No Permission",
    Code: ERROR_NO_PERMISSION,
    Service: "",
    Category: "Auth",
    Description: "No Permission",
  },
];

export {
  STATUS_CODES,
  SUCCESS,
  SUCCESS_BUT_EMPTY,
  ERROR_TOKEN_EXPIRED,
  ERROR_NO_PERMISSION,
};

<template>
  <modal v-model:show="modal" size="lg" body-classes="p-0">
    <template #header>
      <h6 class="modal-title">줌 주소 {{ modalModeText }}</h6>
    </template>

    <card type="secondary" body-classes="px-lg-5">
      <form role="form">
        <br />
        <div class="row-sm-3">
          <preset-radio
            v-model="newData.grade"
            :choices="[
              ['불교대학', '불교대학'],
              ['경전대학', '경전대학'],
            ]"
          />
        </div>
        <base-input v-model="newData.url" type="text">
          <template #addonLeft> 줌 주소</template>
        </base-input>

        <div class="text-center">
          <base-button type="primary" class="my-4" @click="onSave"
            >{{ modalModeText }} 하기</base-button
          >
          <base-button type="secondary" class="my-4" @click="modal = false"
            >닫 기</base-button
          >
        </div>
      </form>
    </card>
  </modal>

  <div></div>
</template>

<script>
import Modal from "@/components/Modal";
import EditModalMixin, { ADD } from "@/views/Template/EditModalMixin";
import * as api from "@/api";
import PresetRadio from "@/components/Inputs/PresetRadio";

export default {
  components: {
    Modal,
    PresetRadio,
  },
  mixins: [EditModalMixin],
  emits: ["addDone", "editDone"],
  data() {
    return {
      newData: {
        id: 0,

        url: "",
      },
    };
  },
  computed: {
    modalModeText: function () {
      return this.modalMode == ADD ? "등록" : "수정";
    },
  },
  methods: {
    //지부에 맞는 지회 검색하기

    // 등록하기
    onAdd() {
      const data = JSON.parse(JSON.stringify(this.newData));

      api.post("manage/class2/zoom", { data }).then(() => {
        this.$emit("addDone", data);
        this.hideModal();
      });
    },
    // 수정하기
    onEdit(row) {
      const data = JSON.parse(JSON.stringify(this.newData));
      api.put(`manage/class2/zoom/${row.id}`, { data }).then(() => {
        this.$emit("editDone", row, data);
        this.hideModal();
      });
    },

    // 등록창 열기
    handleAdd() {
      this.newData.url = "";
      this.showAddModal();
    },
    // 수정창 열기
    handleEdit(row) {
      this.loadModal(`manage/class2/zoom/${row.id}`, row, (data) => {
        this.newData = data;
        return {
          id: data.id,
          url: data.url,
        };
      });
    },
  },
};
</script>

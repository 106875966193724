<!-- 개일별 수업 출석부 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h3 text-white d-inline-block mb-0">개인별출석 현황</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <!-- <div class="col-12">
                   <form class="form-inline mb-2"> -->
                <div v-if="verboseSearchOptionSemester" class="col-sm-2 mb-3">
                  <preset-select
                    v-model="query.semester"
                    placeholder="학기"
                    size="large"
                    :choices="SEMESTER.map((x) => [x, `${x}`])"
                  />
                </div>
                <div v-if="verboseSearchOptionManager" class="col-sm-2 mb-3">
                  <preset-select
                    v-model="query.local"
                    placeholder="지역"
                    size="large"
                    :choices="LOCAL_NAMES_B.map((x) => [x, `${x}`])"
                  />
                </div>
                <div v-if="verboseSearchOptionBranch" class="col-sm-2 mb-3">
                  <preset-select
                    v-model="query.room"
                    placeholder="반"
                    size="large"
                    :choices="ROOM_NUMBER.map((x) => [x, `${x}반`])"
                  />
                </div>
                <div
                  v-if="verboseSearchOptionClassLeader"
                  class="col-sm-3 mb-3"
                >
                  <preset-select
                    v-model="query.team"
                    placeholder="조"
                    size="large"
                    :choices="TEAM_NUMBER.map((x) => [x, `${x}조`])"
                  />
                </div>
                <div v-if="verboseSearchOptionClassLeader" class="col-sm-2">
                  <base-button
                    class="btn-sm"
                    type="secondary"
                    @click="getDataFromApi(1)"
                    >검색</base-button
                  >
                </div>

                <!-- </form>
                </div> -->
              </div>
            </div>
            <!-- Preview Modal -->
            <Modal :modal-active="modalActive" @close="closedModal">
              <div class="modal-content">
                <h6 class="modal-title">출석 세부 정보 보기</h6>

                <div class="card-body mt-0">
                  <table align="center" width="90%">
                    <td class="list-group-item px-2">
                      <H6>수업(전체)</H6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ totalNumber }}회중</div>
                        <div class="child">출석: {{ newData.sum1 }}</div>
                        <div class="child">지각,조퇴: {{ newData.sum2 }}</div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <H6>{{ section1Name }}</H6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ section1Number }}회중</div>
                        <div class="child">
                          출석: {{ newData.onlineSection1Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ newData.onlineSection1Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <H6>{{ section2Name }}</H6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ section2Number }}회중</div>
                        <div class="child">
                          출석: {{ newData.onlineSection2Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ newData.onlineSection2Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <H6>{{ section3Name }}</H6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ section3Number }}회중</div>
                        <div class="child">
                          출석: {{ newData.onlineSection3Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ newData.onlineSection3Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <H6>{{ section4Name }}</H6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ section4Number }}회중</div>
                        <div class="child">
                          출석: {{ newData.onlineSection4Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ newData.onlineSection4Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <H6>즉문즉설</H6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ talkNumber }}회중</div>
                        <div class="child">
                          출석: {{ newData.talkSection1Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ newData.talkSection1Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <H6>실천활동</H6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ offlineNumber }}회중</div>
                        <div class="child">
                          출석: {{ newData.offlineSection1Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ newData.offlineSection1Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                  </table>
                  <div class="text-center">
                    <argon-button
                      size="sm"
                      color="secondary"
                      class="mt-3 text-md"
                      @click="closedModal()"
                      >Close</argon-button
                    >
                  </div>
                </div>
                <!-- <div v-if="kkk" class="card-body mt-0">
                  <table align="center" width="90%">
                    <td class="list-group-item px-2">
                      <H6>수업(전체)</H6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업25회중</div>
                        <div class="child">출석: {{ newData.sum1 }}</div>
                        <div class="child">지각,조퇴: {{ newData.sum2 }}</div>
                      </div>
                    </td>
                    <td class="list-group-item px-2">
                      <H6>{{ section1Name }}</H6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업1회중</div>
                        <div class="child">
                          출석: {{ newData.onlineSection1Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ newData.onlineSection1Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <H6>대승불교/금강경</H6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업7회중</div>
                        <div class="child">
                          출석: {{ newData.onlineSection2Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ newData.onlineSection2Late }}
                        </div>
                      </div>
                    </td>
                    <td class="list-group-item px-2">
                      <H6>반야심경/법화경/화엄경</H6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업6회중</div>
                        <div class="child">
                          출석: {{ newData.onlineSection3Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ newData.onlineSection3Late }}
                        </div>
                      </div>
                    </td>
                    <td class="list-group-item px-2">
                      <H6>육조단경/한국불교/세계불교</H6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업5회중</div>
                        <div class="child">
                          출석: {{ newData.onlineSection4Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ newData.onlineSection4Late }}
                        </div>
                      </div>
                    </td>
                    <td class="list-group-item px-2">
                      <H6>즉문즉설</H6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업2회중</div>
                        <div class="child">
                          출석: {{ newData.talkSection1Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ newData.talkSection1Late }}
                        </div>
                      </div>
                    </td>
                    <td class="list-group-item px-2">
                      <H6>실천활동</H6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업4회중</div>
                        <div class="child">
                          출석: {{ newData.offlineSection1Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ newData.offlineSection1Late }}
                        </div>
                      </div>
                    </td>
                  </table>
                  <div class="text-center">
                    <argon-button
                      size="sm"
                      color="secondary"
                      class="mt-3 text-md"
                      @click="closedModal()"
                      >Close</argon-button
                    >
                  </div> -->
                <!-- </div> -->
              </div>
            </Modal>
            <!-- t: top, b: bottom, s: left, e: right x: left & rigith y: top & bottom -->
            <div class="row" style="position: relative; height: ">
              <div class="col-sm-1">
                <preset-select
                  v-model="query.bound"
                  size="sm"
                  :choices="[
                    ['5', '5'],
                    ['10', '10'],
                    ['15', '15'],
                    ['20', '20'],
                  ]"
                  @change="getDataFromApi(1)"
                />
              </div>
              <div
                class="col"
                style="position: absolute; bottom: 0px; left: 140px; width:auto;"
              >
                total: {{ total }}
              </div>
              <div class="col text-end" style="height:30px">
                <base-button type="primary" class="btn-sm me-2" :class="{disabled : disabled}" @click="excelSave"
                  >다운로드</base-button
                >
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-flush">
                <thead>
                  <th v-for="item in header" :key="item.index">{{ item }}</th>
                </thead>
                <tbody>
                  <tr v-for="row in tableData" :key="row.index">
                    <td>{{ row.no }}</td>
                    <td>{{ row.dept }}</td>
                    <td>
                      <argon-button
                        size="sm"
                        color="secondary"
                        @click="showDetail(row)"
                        >{{ row.name }}</argon-button
                      >
                    </td>
                    <td>{{ row.sumAttend }}</td>
                    <td>{{ row.lessonAttend }}</td>
                    <td>{{ row.onlineAttend }}</td>
                    <td>{{ row.onlineLate }}</td>
                    <td>{{ row.offlineSection1Attend }}</td>
                    <td>{{ row.offlineSection1Late }}</td>
                    <td>{{ row.talkSection1Attend }}</td>
                    <td>{{ row.talkSection1Late }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- End List -->
            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                :rows-per-page="perPage"
                :total="total"
                :model-value="page2"
                @page-change="getDataFromApi"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import { ElTable, ElTableColumn } from "element-plus";
import * as api from "@/api";
import { ref } from "vue";
import PresetSelect from "@/components/Inputs/PresetSelect";
import Modal from "./Board/Modal"; 
import {
  ROOM_NUMBER,
  TEAM_NUMBER,
  LOCAL_NAMES_B,
  SEMESTER,
  BSECTION,
  KSECTION,
  BLESSON_NUMBER,
  KLESSON_NUMBER,
} from "@/consts/class";

export default {
  // 컴포넌트 초기화
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    PresetSelect,
    Modal,
  },
  setup() {
    const tableData = ref([]);
    const perPage = ref(20); // 페이지당 레코드 수
    let page2 = ref(1);
    const total = ref(0); // 총 레코드 수
    const header = ref([
      "No",
      "소 속",
      "이 름",
      "총출석수",
      "법문듣기",
      "수업출석",
      "수업지각/조퇴",
      "실천활동출석",
      "실천활동지각/조퇴",
      "즉문즉설출석",
      "즉문즉설지각/조퇴",
    ]);
    const query = ref({
      semester: "",
      local: "",
      room: "",
      team: "",
      bound: 20,
    });
    let verboseSearchOptionManager = ref(false);
    let verboseSearchOptionBranch = ref(false);
    let verboseSearchOptionMaster = ref(false);
    let verboseSearchOptionSemester = ref(false);
    let verboseSearchOptionClassLeader = ref(false);
    const newData = ref({});
    let buldae = ref(false);
    let kkk = ref(false);
    let modalActive = ref(false);
    const semester = ref("");
    const year = ref(0);
    const grade = ref("");
    let section1Name = ref("");
    let section2Name = ref("");
    let section3Name = ref("");
    let section4Name = ref("");
    let section1Number = ref(0);
    let section2Number = ref(0);
    let section3Number = ref(0);
    let section4Number = ref(0);
    let talkNumber = ref(0);
    let offlineNumber = ref(0);
    let totalNumber = ref(0);
    modalActive.value = false;
    const token = api.Auth.tokenContent;
    let disabled = ref(false) // 엑셀 다운로드 delay 시간 동안 버튼 비활성화
    // grade.value = token.grade;
    // year.value = 2022;
    // semester.value = "9월";
    query.value.bound = token.listType;
    perPage.value = query.value.bound;

    const getDataFromApi = (page = 1) => {
      page2.value = page;
      perPage.value = query.value.bound;
      // const token = api.Auth.tokenContent;
      // query.value.bound = token.listType;
      if (query.value.semester) {
        const temp = query.value.semester.split(" ");
        grade.value = temp[0];
        year.value = temp[1];
        semester.value = temp[2];
      } else {
        grade.value = token.grade;
        year.value = token.year;
        semester.value = token.semester;
      }
      // if (query.value.semester == "불교대학 2022 9월") {
      //   grade.value = "불교대학";
      // } else if (query.value.semester == "경전대학 2022 9월") {
      //   grade.value = "경전대학";
      // }
      for (const field of ["local", "team", "room"]) {
        if (query.value[field] === "전체") {
          query.value[field] = "";
        }
      }
      // if (
      //   query.value.semester &&
      //   (token.role === "manager" || token.role === "master")
      // ) {
      //   const temp = query.value.semester.split(" ");
      //   grade.value = temp[0];
      // }
      // 2024. 4. 17 수정   
       
        let onlineLessonNumber = 0;
        if(grade.value == "불교대학") {
          onlineLessonNumber =  BLESSON_NUMBER.online;
          talkNumber.value = BLESSON_NUMBER.talk;  
          offlineNumber.value = BLESSON_NUMBER.offline; 
        } else if(grade.value == "경전대학") {
          onlineLessonNumber =  KLESSON_NUMBER.online;
          talkNumber.value = KLESSON_NUMBER.talk;  
          offlineNumber.value = KLESSON_NUMBER.offline; 
        }
       
     

      const url = `manage/class2/attend?bound=${query.value.bound}
   &page=${page}&local=${query.value.local}&room=${query.value.room}&team=${query.value.team}&grade=${grade.value}&year=${year.value}&semester=${semester.value}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;  
        if (data.rows > 0) {
          for (const row of d) {
            row.dept = row.local + "/" + row.room + "/" + row.team;
            row.onlineAttend = 0;
            row.onlineLate = 0;
            row.offlineSection1Attend = 0;
            row.offlineSection1Late = 0;
            row.talkSection1Attend = 0;
            row.talkSection1Late = 0;
            row.lessonAttend = 0;
            for (let i = 1; i < onlineLessonNumber + 1; i++) {
              if(eval("row.onlineLesson" + i +".attend == 1")) {
                row.onlineAttend++; 
              } else if (eval("row.onlineLesson" + i +".attend == -1") || eval("row.onlineLesson" + i +".attend == -2")) {
                row.onlineAttend++; 
                row.onlineLate++;
              } 
              if(eval("row.onlineLesson" + i +".isLesson1 == 1")) {
                row.lessonAttend++; 
              }  
            }
            
            for (let i = 1; i < offlineNumber.value + 1; i++) {
              if(eval("row.offlineLesson" + i +".attend == 1")) {
                row.offlineSection1Attend++; 
              } else if (eval("row.offlineLesson" + i +".attend == -1") || eval("row.offlineLesson" + i +".attend == -2")) {
                row.offlineSection1Attend++; 
                row.offlineSection1Late++;
              } 
            }
            for (let i = 1; i < talkNumber.value + 1; i++) {
              if(eval("row.talkLesson" + i +".attend == 1")) {
                row.talkSection1Attend++; 
              } else if (eval("row.talkLesson" + i +".attend == -1") || eval("row.talkLesson" + i +".attend == -2")) {
                row.talkSection1Attend++; 
                row.talkSection1Late++;
              } 
            }
             
            row.sumAttend =
              row.onlineAttend +
              row.offlineSection1Attend +
              row.talkSection1Attend;
          }
        }
        tableData.value = d;
        total.value = data.total;
      }); 
    
    };
    const created = () => {
      // 페이지 생성 시 호출
      const token = api.Auth.tokenContent;
      verboseSearchOptionManager.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("mentor");
      verboseSearchOptionBranch.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("mentor") ||
        token.role.includes("branch");
      verboseSearchOptionClassLeader.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("mentor") ||
        token.role.includes("class leader") ||
        token.role.includes("branch") ||
        token.role.includes("observer");
      verboseSearchOptionMaster.value =
        token.role.includes("master") || token.role.includes("manager");
      verboseSearchOptionSemester.value =
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("manager") ||
        token.role.includes("mentor");
    };
    created();
    getDataFromApi(1);

    const showDetail = (row) => {
      modalActive.value = true;
      const url2 = `manage/class2/lesson?bound=100&grade=${grade.value}&year=${year.value}&semester=${semester.value}`;
      api.get(url2).then((response2) => {
        if (!api.isSuccess(response2)) {
          return;
        }
        const data2 = response2.data;
        const d2 = data2.data;  
        const section1 = [];
        const section2 = [];
        const section3 = [];
        const section4 = [];
        let section = [];
        if(grade.value == "불교대학") {
          section = BSECTION;
        } else {
          section = KSECTION;
        } 
        if (data2.rows > 0) {
          for (const row of d2) {
               if(row.section == section[1]) {
                section1.push(row.lessonNo);
               } else if (row.section == section[2]) {
                section2.push(row.lessonNo);
               } else if (row.section == section[3]) {
                section3.push(row.lessonNo);
               } else if (row.section == section[4]) {
                section4.push(row.lessonNo);
               }
          }
        }  
        section1Number.value = section1.length;
        section2Number.value = section2.length;
        section3Number.value = section3.length;
        section4Number.value = section4.length;

      api
        .get(`manage/class2/attend2/${row.memberNo}`, {})
        .then((response) => {
          if (api.isSuccess(response)) {
            const data = response.data.data;  
            newData.value = response.data.data; 
            data.onlineSection1Attend = 0;
            data.onlineSection1Late = 0; 
            data.onlineSection2Attend = 0;
            data.onlineSection2Late = 0; 
            data.onlineSection3Attend = 0;
            data.onlineSection3Late = 0; 
            data.onlineSection4Attend = 0;
            data.onlineSection4Late = 0; 
            data.offlineSection1Attend = 0;
            data.offlineSection1Late = 0;
            data.talkSection1Attend = 0;
            data.talkSection1Late = 0;
            section1Name.value = "";
            section2Name.value = "";
            section3Name.value = "";
            section4Name.value = "";
            for(let i = 0; i < section1.length; i++) {
              if(eval("data.onlineLesson" + section1[i] + ".attend == 1")) {
                data.onlineSection1Attend++;
              } else if(eval("data.onlineLesson" + section1[i] + ".attend == -1") ) {
                data.onlineSection1Attend++;
                data.onlineSection1Late++;
              } else if(eval("data.onlineLesson" + section1[i] + ".attend == -2") ) {
                data.onlineSection1Attend++;
                data.onlineSection1Late++;
              }
            }
            for(let i = 0; i < section2.length; i++) {
              if(eval("data.onlineLesson" + section2[i] + ".attend == 1")) {
                data.onlineSection2Attend++;
              } else if(eval("data.onlineLesson" + section2[i] + ".attend == -1") ) {
                data.onlineSection2Attend++;
                data.onlineSection2Late++;
              } else if(eval("data.onlineLesson" + section2[i] + ".attend == -2") ) {
                data.onlineSection2Attend++;
                data.onlineSection2Late++;
              }
            }
            for(let i = 0; i < section3.length; i++) {
              if(eval("data.onlineLesson" + section3[i] + ".attend == 1")) {
                data.onlineSection3Attend++;
              } else if(eval("data.onlineLesson" + section3[i] + ".attend == -1") ) {
                data.onlineSection3Attend++;
                data.onlineSection3Late++;
              } else if(eval("data.onlineLesson" + section3[i] + ".attend == -2") ) {
                data.onlineSection3Attend++;
                data.onlineSection3Late++;
              }
            }
            for(let i = 0; i < section4.length; i++) {
              if(eval("data.onlineLesson" + section4[i] + ".attend == 1")) {
                data.onlineSection4Attend++;
              } else if(eval("data.onlineLesson" + section4[i] + ".attend == -1") ) {
                data.onlineSection4Attend++;
                data.onlineSection4Late++;
              } else if(eval("data.onlineLesson" + section4[i] + ".attend == -2") ) {
                data.onlineSection4Attend++;
                data.onlineSection4Late++;
              }
            }

            for (let i = 1; i < offlineNumber.value + 1; i++) {
              if(eval("data.offlineLesson" + i +".attend == 1")) {
                data.offlineSection1Attend++; 
              } else if (eval("data.offlineLesson" + i +".attend == -1") || eval("data.offlineLesson" + i +".attend == -2")) {
                data.offlineSection1Attend++; 
                data.offlineSection1Late++;
              } 
            }
            for (let i = 1; i < talkNumber.value + 1; i++) {
              if(eval("data.talkLesson" + i +".attend == 1")) {
                data.talkSection1Attend++; 
              } else if (eval("data.talkLesson" + i +".attend == -1") || eval("data.talkLesson" + i +".attend == -2")) {
                data.talkSection1Attend++;
                data.talkSection1Late++;
              } 
            }
            totalNumber.value = section1Number.value + section2Number.value + section3Number.value + section4Number.value + talkNumber.value + offlineNumber.value;
            
            if (data.grade === "불교대학") { 
              section1Name.value = BSECTION[1];
              section2Name.value = BSECTION[2];
              section3Name.value = BSECTION[3];
              section4Name.value = BSECTION[4];
            } else if (data.grade === "경전대학") { 
              section1Name.value = KSECTION[1];
              section2Name.value = KSECTION[2];
              section3Name.value = KSECTION[3];
              section4Name.value = KSECTION[4];
            }
            newData.value.sum2 =
              data.onlineSection1Late +
              data.onlineSection2Late +
              data.onlineSection3Late +
              data.onlineSection4Late +
              data.talkSection1Late +
              data.offlineSection1Late;
            newData.value.sum1 =
              data.onlineSection1Attend +
              data.onlineSection2Attend +
              data.onlineSection3Attend +
              data.onlineSection4Attend +
              data.talkSection1Attend +
              data.offlineSection1Attend -
              parseInt(
                (data.onlineSection1Late +
                  data.onlineSection2Late +
                  data.onlineSection3Late +
                  data.onlineSection4Late +
                  data.talkSection1Late +
                  data.offlineSection1Late) /
                  3
              );
          }
        });
      });
    };
    const closedModal = () => {
      modalActive.value = false;
    };
    const excelSave = () => {
      const path = `manage/class2/download2?grade=${grade.value}&local=${query.value.local}&team=${query.value.team}&room=${query.value.room}`;
      api.download(path);
      // 엑셀 다운로드 중 중복 클릭 제한
      disabled.value = true
      setTimeout(()=>{
        disabled.value = false
      },5000)
    };
    return {
      header,
      page2,
      ROOM_NUMBER,
      TEAM_NUMBER,
      LOCAL_NAMES_B,
      SEMESTER,
      semester,
      newData,
      year,
      query,
      getDataFromApi,
      tableData,
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      verboseSearchOptionManager,
      verboseSearchOptionSemester,
      verboseSearchOptionBranch,
      verboseSearchOptionMaster,
      verboseSearchOptionClassLeader,
      modalActive,
      buldae,
      kkk,
      closedModal,
      showDetail,
      excelSave,
      disabled,
      section1Name,
      section2Name,
      section3Name,
      section4Name,
      section1Number,
      section2Number,
      section3Number,
      section4Number,
      talkNumber,
      offlineNumber,
      totalNumber,
    };
  },
  mounted() {
    const modal = document.querySelectorAll(".modal-inner");
    const modal2 = document.querySelectorAll(".modal-content");
    if (this.$store.state.darkMode) {
      for (var i = 0; i < modal.length; i++) {
        modal[i].style.backgroundColor = "#172b4d";
        modal2[i].style.backgroundColor = "#172b4d";
      }
    }
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.modal-content {
  display: flex;
  flex-direction: column;
}

table {
  width: 100%;
  text-align: left;
}
table th {
  padding: 12px;
  border-bottom: 2px solid darkgray;
  text-align: center;
  background-color: rgba(184, 219, 196, 0.1);
}
table td {
  padding: 12px;
  text-align: center;
}
table tr:nth-of-type(even) {
  background-color: rgba(226, 226, 245, 0.1);
}
.parent {
  display: flex;
  padding: 6px;
}
.parent .child {
  flex: 1;
  text-align: center;
}
p {
  font-weight: bold;
}
</style>

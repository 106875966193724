export const ATTEND_TYPES = {
  0: "결석",
  1: "출석",
  "-1": "지각",
  "-2": "조퇴",
};

export const ATTEND_TYPE_COLORS = {
  0: "text-red",
  1: "text-blue",
  "-1": "text-gray",
  "-2": "text-gray",
};

<!-- 조별 주소 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h3 class="text-white d-inline-block mb-0">온라인수업 출석부</h3>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <!-- <div class="col-12">
                   <form class="form-inline mb-2"> -->
                <div v-if="verboseSearchOptionSemester" class="col-sm-2">
                  <preset-select
                    v-model="query.semester"
                    placeholder="학기"
                    size="large"
                    :choices="SEMESTER.map((x) => [x, `${x}`])"
                    @change="gradeChange()"
                  />
                </div>
                <div v-if="verboseSearchOptionManager" class="col-sm-2">
                  <preset-select
                    v-model="query.local"
                    placeholder="지역"
                    size="large"
                    :choices="LOCAL_NAMES_B.map((x) => [x, `${x}`])"
                  />
                </div>
                <div v-if="verboseSearchOptionBranch" class="col-sm-2">
                  <preset-select
                    v-model="query.room"
                    placeholder="반"
                    size="large"
                    :choices="ROOM_NUMBER.map((x) => [x, `${x}반`])"
                  />
                </div>
                <div v-if="verboseSearchOptionClassLeader" class="col-sm-2">
                  <preset-select
                    v-model="query.team"
                    placeholder="조"
                    size="large"
                    :choices="TEAM_NUMBER.map((x) => [x, `${x}조`])"
                  />
                </div>
                <div v-if="bsection" class="col-sm-2">
                  <preset-select
                    v-model="query.lessonName"
                    placeholder="수업명"
                    size="large"
                    :choices="BLESSON_NAMES.map((x) => [x, `${x}`])"
                  />
                </div>
                <div v-if="ksection" class="col-sm-2">
                  <preset-select
                    v-model="query.lessonName"
                    placeholder="수업명"
                    size="large"
                    :choices="KLESSON_NAMES.map((x) => [x, `${x}`])"
                  />
                </div>
                <div v-if="bsection2" class="col-sm-2">
                  <preset-select
                    v-model="query.lessonName"
                    placeholder="수업명"
                    size="large"
                    :choices="BLESSON_NAMES2.map((x) => [x, `${x}`])"
                  />
                </div>
                <div v-if="ksection2" class="col-sm-2">
                  <preset-select
                    v-model="query.lessonName"
                    placeholder="수업명"
                    size="large"
                    :choices="KLESSON_NAMES2.map((x) => [x, `${x}`])"
                  />
                </div>
                <div v-if="verboseSearchOptionClassLeader" class="col-sm-2">
                  <preset-select
                    v-model="query.confirm"
                    placeholder="전체"
                    size="large"
                    :choices="[
                      ['', '전체'],
                      [1, '확정'],
                      [0, '대기'],
                    ]"
                  />
                </div>
                <div class="col-sm-2 mt-3">
                  <base-button
                    class="btn-sm"
                    type="secondary"
                    @click="getDataFromApi(1)"
                    >검색</base-button
                  >
                </div>

                <!-- </form>
                </div> -->
              </div>
            </div>
            <!-- Preview Modal -->
            <Modal :modal-active="modalActive" @close="closedModal">
              <div class="modal-content">
                <h6 class="modal-title">출석 세부 정보 보기</h6>

                <div class="card-body mt-0">
                  <table align="center" width="90%">
                    <td class="list-group-item px-2">
                      <h6>수업(전체)</h6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ totalNumber }}회중</div>
                        <div class="child">출석: {{ newData.sum1 }}</div>
                        <div class="child">지각,조퇴: {{ newData.sum2 }}</div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <h6>{{ section1Name }}</h6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ section1Number }}회중</div>
                        <div class="child">
                          출석: {{ newData.onlineSection1Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ newData.onlineSection1Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <h6>{{ section2Name }}</h6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ section2Number }}회중</div>
                        <div class="child">
                          출석: {{ newData.onlineSection2Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ newData.onlineSection2Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <h6>{{ section3Name }}</h6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ section3Number }}회중</div>
                        <div class="child">
                          출석: {{ newData.onlineSection3Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ newData.onlineSection3Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <h6>{{ section4Name }}</h6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ section4Number }}회중</div>
                        <div class="child">
                          출석: {{ newData.onlineSection4Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ newData.onlineSection4Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <h6>즉문즉설</h6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ talkNumber }}회중</div>
                        <div class="child">
                          출석: {{ newData.talkSection1Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ newData.talkSection1Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                    <td class="list-group-item px-2">
                      <h6>실천활동</h6>
                    </td>
                    <td class="list-group-item px-2">
                      <div class="parent">
                        <div class="child">수업 {{ offlineNumber }}회중</div>
                        <div class="child">
                          출석: {{ newData.offlineSection1Attend }}
                        </div>
                        <div class="child">
                          지각,조퇴: {{ newData.offlineSection1Late }}
                        </div>
                      </div>
                    </td>
                    <br />
                    <div class="text-center">
                      <base-button
                        type="secondary"
                        class="my-4 ms-2"
                        @click="closedModal"
                        >닫 기</base-button
                      >
                    </div>
                  </table>
                </div>                  
              </div>
            </Modal>
            <!-- t: top, b: bottom, s: left, e: right x: left & rigith y: top & bottom -->
            <div class="row" style="position: relative; height: ">
              <div class="col-sm-1">
                <preset-select
                  v-model="query.bound"
                  size="sm"
                  :choices="[
                    ['5', '5'],
                    ['10', '10'],
                    ['15', '15'],
                    ['20', '20'],
                  ]"
                  @change="getDataFromApi(1)"
                />
              </div>
              <div
                class="col"
                style="position: absolute; bottom: 0px; left: 140px; width:auto;"
              >
                total: {{ total }}
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-flush">
                <thead>
                  <th>No</th>
                  <th>학 년</th>
                  <th>수업번호</th>
                  <th>수업명</th>
                  <th>수업일</th>
                  <th>반</th>
                  <th>조</th>
                  <th>이름</th>
                  <th v-if="isLesson1">법문1듣기</th>
                  <th v-if="isLesson2">법문2듣기</th>
                  <th>수업참여</th>
                  <th>출석결과</th>
                  <!-- v-if="modify" 23년 하반기 추가 -->
                  <th>진행자확인</th>
                </thead>
                <tbody>
                  <tr v-for="row in tableData" :key="row.index">
                    <td>{{ row.no }}</td>
                    <td>{{ row.grade }}</td>
                    <td>{{ row.lessonNo }}</td>
                    <td>{{ row.lessonName }}</td>
                    <td>{{ row.lessonDate }}</td>
                    <td>{{ row.roomCode }}</td>
                    <td>{{ row.team }}</td>
                    <td>
                      <argon-button
                        size="small"
                        color="secondary"
                        @click="showDetail(row)"
                        >{{ row.name }}</argon-button
                      >
                    </td>
                    <td v-if="isLesson1">
                      <preset-select
                        v-model="row.isLesson1"
                        :choices="[
                          [0, '결석'],
                          [1, '출석'],
                        ]"
                        :disabled="row.confirm1 === true"
                        @change="onIsLessonChange1($event, row)"
                      />
                    </td>
                    <td v-if="isLesson2">
                      <preset-select
                        v-model="row.isLesson2"
                        :choices="[
                          [0, '결석'],
                          [1, '출석'],
                        ]"
                        :disabled="row.confirm1 === true"
                        @change="onIsLessonChange2($event, row)"
                      />
                    </td>
                    <td>
                      <preset-select
                        v-model="row.isMeet"
                        :choices="[
                          [0, '결석'],
                          [1, '출석'],
                          [-1, '지각'],
                          [-2, '조퇴'],
                        ]"
                        :disabled="row.confirm1 === true"
                        @change="onIsMeetChange($event, row)"
                      />
                    </td>
                    <td v-if="row.newAttend === 0" class="text-warning">
                      <attend-text :value="row.newAttend" />
                    </td>
                    <td v-else class="text-primary">
                      <attend-text :value="row.newAttend" />
                    </td>
                    <td>
                      <argon-switch
                        v-if="modify"
                        :id="row.id"
                        :checked="row.confirm2"
                        :disabled="row.confirm2"
                        :name="row.lessonName"
                        @click.prevent="onAttendChange(row)"
                      >
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                          >{{ row.checkState }}</label
                        >
                      </argon-switch>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- End List -->
            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                :rows-per-page="perPage"
                :total="total"
                :model-value="page2"
                @page-change="getDataFromApi"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import * as api from "@/api";
import { ref } from "vue";
import PresetSelect from "@/components/Inputs/PresetSelect";
import ArgonSwitch from "@/components/ArgonSwitch";
import AttendText from "./AttendText";
import moment from "moment";
import {
  SEMESTER,
  LOCAL_NAMES_B,
  ROOM_NUMBER,
  TEAM_NUMBER,
} from "@/consts/class";
import Modal from "./Board/Modal.vue";
import { BLESSON_NAMES, KLESSON_NAMES, BLESSON_NAMES2, KLESSON_NAMES2, BSECTION, KSECTION, BLESSON_NUMBER, KLESSON_NUMBER } from "@/consts/class";

export default {
  // 컴포넌트 초기화
  components: {
    PresetSelect,
    ArgonSwitch,
    AttendText,
    Modal,
  },
  setup() {
    const tableData = ref([]);
    let buldae = ref(false);
    let kkk = ref(false);
    const isLesson1 = ref(false);
    const isLesson2 = ref(false);
    const newData = ref({});
    const checkState = ref("");
    const perPage = ref(20); // 페이지당 레코드 수
    let page2 = ref(1);
    const total = ref(0); // 총 레코드 수
    const header = ref([
      "No",
      "학 년",
      "수업번호",
      "수업명",
      "수업일",
      "반",
      "조",
      "이 름",
      "법문1듣기",
      "법문2듣기",
      "수업참여",
      "출석결과",
      "진행자확인",
    ]);
    const query = ref({
      semester: "",
      local: "",
      room: "",
      team: "",
      bound: 20,
      confirm: "",
      lessonNo: "",
      lessonName: "",
    });
    const semester = ref("");
    const year = ref(0);
    const grade = ref("");
    let lessonNo = 0;
    let lessonName = "";
    let lessonDate = "";
    let section = "";
    let reason = "";
    let modalActive = ref(false);
    let verboseSearchOptionManager = ref(false);
    let verboseSearchOptionBranch = ref(false);
    let verboseSearchOptionMaster = ref(false);
    let verboseSearchOptionSemester = ref(false);
    let verboseSearchOptionClassLeader = ref(false);
    let bsection = ref(false);
    let ksection = ref(false);
    let bsection2 = ref(false);
    let ksection2 = ref(false);
    let modify = ref(true);
    let section1Name = ref("");
    let section2Name = ref("");
    let section3Name = ref("");
    let section4Name = ref("");
    let section1Number = ref(0);
    let section2Number = ref(0);
    let section3Number = ref(0);
    let section4Number = ref(0);
    let talkNumber = ref(0);
    let offlineNumber = ref(0);
    let totalNumber = ref(0);

    // const token = api.Auth.tokenContent;
    // query.value.bound = token.listType;
    // if (token.grade == "경전대학") {
    //   grade.value = "경전대학";
    //   bsection.value = false;
    //   ksection.value = true;
    //   (query.value.semester = "경전대학 2022 9월"),
    //     (query.value.lessonName = "대승불교 1, 2강");
    // } else {
    //   grade.value = "불교대학";
    //   bsection.value = true;
    //   ksection.value = false;
    //   (query.value.semester = "불교대학 2022 9월"),
    //     (query.value.lessonName = "입학식");
    // }
    const token = api.Auth.tokenContent;
    query.value.bound = token.listType;
    perPage.value = query.value.bound;
    if (grade.value == "") {
      grade.value = token.grade;
    }
    // let now = moment();
    // moment.tz.setDefault("Asia/Seoul");
    // let date = now.format("2023-09-15 09:00:00");
    //let date = now.format("YYYY-MM-DD");
    // let diffDay = "";
    // let tempA = 0;
    // if (grade.value === "불교대학") {
    //   diffDay = moment("2023-09-12 10:00:00").diff(date, "hours") * -1;
    //   tempA = parseInt(diffDay / 168) + 1;
    //   query.value.lessonName = BLESSON_NAMES2[tempA - 1];
    // }
    // if (grade.value === "경전대학") {
    //   diffDay = moment("2023-09-14 10:00:00").diff(date, "hours") * -1;
    //   tempA = parseInt(diffDay / 168);
    //   query.value.lessonName = KLESSON_NAMES2[tempA - 1];
    // }
    const gradeChange = () => {
      const temp = query.value.semester.split(" ");
      grade.value = temp[0];
      if(temp[1] > 2023) {
        if (grade.value == "불교대학") {
          bsection.value = true;
          ksection.value = false;
          bsection2.value = false;
          ksection2.value = false;
        } else if (grade.value == "경전대학") {
          bsection.value = false;
          ksection.value = true;
          bsection2.value = false;
          ksection2.value = false;
        }
      } else {
        if (grade.value == "불교대학") {
          bsection.value = false;
          ksection.value = false;
          bsection2.value = true;
          ksection2.value = false;
        } else if (grade.value == "경전대학") {
          bsection.value = false;
          ksection.value = false;
          bsection2.value = false;
          ksection2.value = true;
        }
      }
    };
    const created = () => {
      // 페이지 생성 시 호출
      const token = api.Auth.tokenContent;
      verboseSearchOptionSemester.value =
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("manager") ||
        token.role.includes("mentor");
      verboseSearchOptionManager.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("mentor");
      verboseSearchOptionBranch.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("mentor") ||
        token.role.includes("branch");
      verboseSearchOptionClassLeader.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("mentor") ||
        token.role.includes("class leader") ||
        token.role.includes("branch") ||
        token.role.includes("observer");
      verboseSearchOptionMaster.value =
        token.role.includes("master") || token.role.includes("manager");
      verboseSearchOptionSemester.value =
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("manager") ||
        token.role.includes("mentor");
    };
    const getDataFromApi = (page = 1) => {
      modify.value = true;
      isLesson1.value = false;
      isLesson2.value = false;
      page2.value = page;
      perPage.value = query.value.bound;
      // if (query.value.semester == "불교대학 2022 9월") {
      //   grade.value = "불교대학";
      // } else if (query.value.semester == "경전대학 2022 9월") {
      //   grade.value = "경전대학";
      // }

      if (query.value.semester) {
        const temp = query.value.semester.split(" ");
        year.value = temp[1];
        semester.value = temp[2];
        grade.value = temp[0];
      } else {
        year.value = token.year;
        semester.value = token.semester;
        grade.value = token.grade;
      }
      if(year.value > 2023) {
        if (grade.value == "불교대학") {
          bsection.value = true;
          ksection.value = false;
          bsection2.value = false;
          ksection2.value = false;
        } else if (grade.value == "경전대학") {
          bsection.value = false;
          ksection.value = true;
          bsection2.value = false;
          ksection2.value = false;
        }
      } else {
        if (grade.value == "불교대학") {
          bsection.value = false;
          ksection.value = false;
          bsection2.value = true;
          ksection2.value = false;
        } else if (grade.value == "경전대학") {
          bsection.value = false;
          ksection.value = false;
          bsection2.value = false;
          ksection2.value = true;
        }
      }

      if (query.value.local === "전체") {
        query.value.local = "";
      }
      if (query.value.room === "전체") {
        query.value.room = "";
      }
      if (query.value.team === "전체") {
        query.value.team = "";
      }
      // if (
      //   query.value.semester &&
      //   (token.role === "manager" || token.role === "master")
      // ) {
      //   const temp = query.value.semester.split(" ");
      //   grade.value = temp[0];
      // }
      // let today = moment().local().format("YYYY-MM-DD HH:mm");
      // let startDay1 = moment("2023-09-19 10:00");
      // let startDay2 = moment("2023-09-21 10:00");
      // if (!query.value.lessonName) {
      //   if (grade.value === "불교대학") {
      //     if (moment(today) < startDay1) {
      //       query.value.lessonName = "입학식";
      //     }
      //   } else if (grade.value === "경전대학") {
      //     if (moment(today) < startDay2) {
      //       query.value.lessonName = "입학식";
      //     }
      //   }
      // }

      let url2 = "";
      if (query.value.lessonName) {
        url2 = `manage/class2/lesson?lessonName=${query.value.lessonName}&grade=${grade.value}&year=${year.value}&semester=${semester.value}`;
      } else {
        url2 = `manage/class2/lesson?today=today&grade=${grade.value}&year=${year.value}&semester=${semester.value}`;
      }

      api.get(url2).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data2 = response.data;
        const d2 = data2.data;

        // if (query.value.lessonNo) {
        //   lessonNo = query.value.lessonNo;
        // } else {
        if(data2.rows > 0) {
        lessonNo = d2[0].lessonNo;

        if (lessonNo > -1) {
          lessonName = d2[0].name;
          lessonDate = d2[0].lessonDate;
          section = d2[0].section;

          const url = `manage/class2/attend?page=${page}&bound=${query.value.bound}
       &local=${query.value.local}&team=${query.value.team}&room=${query.value.room}&grade=${grade.value}&year=${year.value}&semester=${semester.value}&lessonNo=${lessonNo}&confirm=${query.value.confirm}`;
          api.get(url).then((response) => {
            if (!api.isSuccess(response)) {
              return;
            }
            const data = response.data;
            const d = data.data;

            if (data.rows > 0) {
              for (const row of d) {
                row.lessonName = lessonName;
                row.lessonNo = lessonNo;
                row.lessonDate = lessonDate;
                row.section = section;
                row.roomCode = row.local + row.room + "반";
                row.team = row.team + "조";

                if (row.lessonDate) {
                  row.lessonDate = moment(row.lessonDate).format("YYYY-MM-DD");
                }
                if (
                  eval("row.onlineLesson" + lessonNo + ".isLesson1") ||
                  eval("row.onlineLesson" + lessonNo + ".isLesson1 === 0")
                ) {
                  eval(
                    "row.isLesson1 = row.onlineLesson" + lessonNo + ".isLesson1"
                  );
                  isLesson1.value = true;
                }
                if (
                  eval("row.onlineLesson" + lessonNo + ".isLesson2") ||
                  eval("row.onlineLesson" + lessonNo + ".isLesson2 === 0")
                ) {
                  eval(
                    "row.isLesson2 = row.onlineLesson" + lessonNo + ".isLesson2"
                  );
                  isLesson2.value = true;
                }
                eval("row.isMeet = row.onlineLesson" + lessonNo + ".isMeet");
                eval("row.attend = row.onlineLesson" + lessonNo + ".attend");
                eval(
                  "row.newConfirm = row.onlineLesson" + lessonNo + ".confirm"
                );
                if (
                  !row.isLesson1 &&
                  row.isLesson1 !== 0 &&
                  (!row.isLesson2 || row.isLesson2 !== 0)
                ) {
                  if (row.isMeet === 1) {
                    row.newAttend = 1;
                  } else if (row.isMeet === -1) {
                    row.newAttend = -1;
                  } else if (row.isMeet === -2) {
                    row.newAttend = -2;
                  } else if (row.isMeet === 0) {
                    row.newAttend = 0;
                  }
                } else if (
                  !row.isLesson2 &&
                  row.isLesson2 !== 0 &&
                  (row.isLesson1 || row.isLesson1 === 0)
                ) {
                  if (row.isLesson1 === 1) {
                    if (row.isMeet === 1) {
                      row.newAttend = 1;
                    } else if (row.isMeet === -1) {
                      row.newAttend = -1;
                    } else if (row.isMeet === -2) {
                      row.newAttend = -2;
                    } else if (row.isMeet === 0) {
                      row.newAttend = 0;
                    }
                  } else {
                    row.newAttend = 0;
                  }
                } else {
                  if (row.isLesson1 === 1 && row.isLesson2 === 1) {
                    if (row.isMeet === 1) {
                      row.newAttend = 1;
                    } else if (row.isMeet === -1) {
                      row.newAttend = -1;
                    } else if (row.isMeet === -2) {
                      row.newAttend = -2;
                    } else if (row.isMeet === 0) {
                      row.newAttend = 0;
                    }
                  } else {
                    row.newAttend = 0;
                  }
                }
                if (row.newConfirm == 1) {
                  row.confirm1 = true;
                  row.checkState = "확정";
                  row.confirm2 = false;
                } else {
                  row.confirm1 = false;
                  row.confirm2 = true;
                  row.checkState = "대기";
                }
                // 2023년 9월학기부터 적용
                // if (verboseSearchOptionClassLeader.value) {
                // let today2 = moment();
                // let previousTwoWeek = moment() + 14 * 3600000 * 24;
                // let lessonDate2 = moment(lessonDate);
                // if (today2 > lessonDate2) {
                //   row.confirm1 = true;
                //   modify.value = false;
                // } else if (previousTwoWeek < lessonDate2) {
                //   row.confirm1 = true;
                //   modify.value = false;
                // }
                // }
              }
            }
            tableData.value = d;
            total.value = data.total;
          });
        }
      }
      });
    };

    created();
    getDataFromApi(1);
    const onIsLessonChange1 = (event, row) => {
      if (row.newConfirm !== 0) {
        return;
      }
      const data = {};
      data.isLesson1 = Number(event);
      data.lessonNo = lessonNo;
      api.put(`manage/class2/attend31/${row.id}`, { data }).then((response) => {
        if (api.isSuccess(response)) {
          getDataFromApi();
        }
      });
    };
    const onIsLessonChange2 = (event, row) => {
      if (row.newConfirm !== 0) {
        return;
      }
      const data = {};
      data.isLesson2 = Number(event);
      data.lessonNo = lessonNo;
      api.put(`manage/class2/attend31/${row.id}`, { data }).then((response) => {
        if (api.isSuccess(response)) {
          getDataFromApi();
        }
      });
    };
    const onIsMeetChange = (event, row) => {
      if (row.newConfirm !== 0) {
        return;
      }
      const data = {};
      row.isMeet = Number(event);
      data.isMeet = row.isMeet;
      data.lessonNo = lessonNo;

      api.put(`manage/class2/attend/${row.id}`, { data }).then((response) => {
        if (api.isSuccess(response)) {
          getDataFromApi();
        }
      });
    };
    const onAttendChange = (newData) => {
      if (newData.newConfirm === 1) {
        reason = prompt(
          "출석 기록을 대기로 변경합니다. 변경 사유를 입력 하세요."
        );
        if (reason === "") {
          return;
        }
        newData.newConfirm = 0;
        newData.checkState = "대기";
      } else {
        let response = confirm("출석 기록을 확정합니다.");
        if (response) {
          newData.newConfirm = 1;
          newData.checkState = "확정";
        } else {
          return;
        }
      }
      let data = {
        newConfirm: newData.newConfirm,
      };
      if (reason && newData.newConfirm === 0) {
        newData.reason = reason;
        data.reason = newData.reason;
        newData.confirm = newData.newConfirm;
        data.confirm = newData.confirm;
        data.lessonNo = lessonNo;
        api
          .put(`manage/class2/attend/${newData.id}`, { data })
          .then((response) => {
            if (api.isSuccess(response)) {
              getDataFromApi();
            }
          });
      }
      if (newData.newConfirm === 0) {
        getDataFromApi();
      } else {
        data = {};
        newData.confirm = newData.newConfirm;
        data.confirm = newData.confirm;
        data.attend = newData.newAttend;
        data.lessonNo = lessonNo;
        api
          .put(`manage/class2/attend1/${newData.id}`, { data })
          .then((response) => {
            if (api.isSuccess(response)) {
       
          // 온라인 수업 출석부와 수기 출석부 합쳐야 할때 쓰면 됩니다. 2023-2-13 by 안영섭
          // const url = `manage/class2/meetingAttend2?grade=${d[0].grade}&local=${d[0].local}&room=${d[0].room}&team=${d[0].team}&lessonName=${query.value.lessonName}`;
          // api.get(url).then((response5) => {
          //   if (!api.isSuccess(response5)) {
          //     return;
          //    }
          //    const data5 = response5.data;
          //    const d2 = data5.data;

          //    if(d[0].name === d2[0].student1.name) {
          //      d2[0].student1.attend = newData.newAttend;
          //    } else if (d[0].name === d2[0].student2.name) {
          //      d2[0].student2.attend = newData.newAttend;
          //    } else if (d[0].name === d2[0].student3.name) {
          //      d2[0].student3.attend = newData.newAttend;
          //    } else if (d[0].name === d2[0].student4.name) {
          //      d2[0].student4.attend = newData.newAttend;
          //    } else if (d[0].name === d2[0].student5.name) {
          //      d2[0].student5.attend = newData.newAttend;
          //    } else if (d[0].name === d2[0].student6.name) {
          //      d2[0].student6.attend = newData.newAttend;
          //    } else if (d[0].name === d2[0].student7.name) {
          //      data.student7.attend = newData.newAttend;
          //    } else if (d[0].name === d2[0].student8.name) {
          //      d2[0].student8.attend = newData.newAttend;
          //    } else if (d[0].name === d2[0].student9.name) {
          //      d2[0].student9.attend = newData.newAttend;
          //    } else if (d[0].name === d2[0].student10.name) {
          //      d2[0].student10.attend = newData.newAttend;
          //    }
          //    const data = d2[0];
          //    api.put(`manage/class2/meetingAttend2/${d2[0].id}`, { data }).then(() => {
          //    });
          //  });
        // });
              getDataFromApi();
            }
          });
        
      }
    };

    const showDetail = (row) => {
      modalActive.value = true;
      const url2 = `manage/class2/lesson?bound=100&grade=${grade.value}&year=${year.value}&semester=${semester.value}`;
      api.get(url2).then((response2) => {
        if (!api.isSuccess(response2)) {
          return;
        }
        const data2 = response2.data;
        const d2 = data2.data;  
        const section1 = [];
        const section2 = [];
        const section3 = [];
        const section4 = [];
        let section = [];
        if(grade.value == "불교대학") {
          section = BSECTION;
        } else {
          section = KSECTION;
        } 
        if (data2.rows > 0) {
          for (const row of d2) {
               if(row.section == section[1]) {
                section1.push(row.lessonNo);
               } else if (row.section == section[2]) {
                section2.push(row.lessonNo);
               } else if (row.section == section[3]) {
                section3.push(row.lessonNo);
               } else if (row.section == section[4]) {
                section4.push(row.lessonNo);
               }
          }
        }   
        section1Number.value = section1.length;
        section2Number.value = section2.length;
        section3Number.value = section3.length;
        section4Number.value = section4.length;
      api
        .get(`manage/class2/attend2/${row.memberNo}`, {})
        .then((response) => {
          if (api.isSuccess(response)) {
            const data = response.data.data;
            newData.value = response.data.data; 
            if(grade.value == "불교대학") { 
              talkNumber.value = BLESSON_NUMBER.talk;  
              offlineNumber.value = BLESSON_NUMBER.offline; 
            } else if(grade.value == "경전대학") { 
              talkNumber.value = KLESSON_NUMBER.talk;  
              offlineNumber.value = KLESSON_NUMBER.offline; 
            }

             
            data.onlineSection1Attend = 0;
            data.onlineSection1Late = 0; 
            data.onlineSection2Attend = 0;
            data.onlineSection2Late = 0; 
            data.onlineSection3Attend = 0;
            data.onlineSection3Late = 0; 
            data.onlineSection4Attend = 0;
            data.onlineSection4Late = 0; 
            data.offlineSection1Attend = 0;
            data.offlineSection1Late = 0;
            data.talkSection1Attend = 0;
            data.talkSection1Late = 0;
            section1Name.value = "";
            section2Name.value = "";
            section3Name.value = "";
            section4Name.value = "";
            for(let i = 0; i < section1.length; i++) {
              if(eval("data.onlineLesson" + section1[i] + ".attend == 1")) {
                data.onlineSection1Attend++;
              } else if(eval("data.onlineLesson" + section1[i] + ".attend == -1") ) {
                data.onlineSection1Attend++;
                data.onlineSection1Late++;
              } else if(eval("data.onlineLesson" + section1[i] + ".attend == -2") ) {
                data.onlineSection1Attend++;
                data.onlineSection1Late++;
              }
            }
            for(let i = 0; i < section2.length; i++) {
              if(eval("data.onlineLesson" + section2[i] + ".attend == 1")) {
                data.onlineSection2Attend++;
              } else if(eval("data.onlineLesson" + section2[i] + ".attend == -1") ) {
                data.onlineSection2Attend++;
                data.onlineSection2Late++;
              } else if(eval("data.onlineLesson" + section2[i] + ".attend == -2") ) {
                data.onlineSection2Attend++;
                data.onlineSection2Late++;
              }
            }
            for(let i = 0; i < section3.length; i++) {
              if(eval("data.onlineLesson" + section3[i] + ".attend == 1")) {
                data.onlineSection3Attend++;
              } else if(eval("data.onlineLesson" + section3[i] + ".attend == -1") ) {
                data.onlineSection3Attend++;
                data.onlineSection3Late++;
              } else if(eval("data.onlineLesson" + section3[i] + ".attend == -2") ) {
                data.onlineSection3Attend++;
                data.onlineSection3Late++;
              }
            }
            for(let i = 0; i < section4.length; i++) {              
              if(eval("data.onlineLesson" + section4[i] + ".attend == 1")) {
                data.onlineSection4Attend++;
              } else if(eval("data.onlineLesson" + section4[i] + ".attend == -1") ) {
                data.onlineSection4Attend++;
                data.onlineSection4Late++;
              } else if(eval("data.onlineLesson" + section4[i] + ".attend == -2") ) {
                data.onlineSection4Attend++;
                data.onlineSection4Late++;
              }    
            } 
            for (let i = 1; i < offlineNumber.value + 1; i++) {
              if(eval("data.offlineLesson" + i +".attend == 1")) {
                data.offlineSection1Attend++; 
              } else if (eval("data.offlineLesson" + i +".attend == -1") || eval("data.offlineLesson" + i +".attend == -2")) {
                data.offlineSection1Attend++; 
                data.offlineSection1Late++;
              } 
            }
            for (let i = 1; i < talkNumber.value + 1; i++) {
              if(eval("data.talkLesson" + i +".attend == 1")) {
                data.talkSection1Attend++; 
              } else if (eval("data.talkLesson" + i +".attend == -1") || eval("data.talkLesson" + i +".attend == -2")) {
                data.talkSection1Attend++;
                data.talkSection1Late++;
              } 
            }
            totalNumber.value = section1Number.value + section2Number.value + section3Number.value + section4Number.value + talkNumber.value + offlineNumber.value;
            
            if (data.grade === "불교대학") { 
              section1Name.value = BSECTION[1];
              section2Name.value = BSECTION[2];
              section3Name.value = BSECTION[3];
              section4Name.value = BSECTION[4];
            } else if (data.grade === "경전대학") { 
              section1Name.value = KSECTION[1];
              section2Name.value = KSECTION[2];
              section3Name.value = KSECTION[3];
              section4Name.value = KSECTION[4];
            }
            newData.value.sum2 =
              data.onlineSection1Late +
              data.onlineSection2Late +
              data.onlineSection3Late +
              data.onlineSection4Late +
              data.talkSection1Late +
              data.offlineSection1Late;
            newData.value.sum1 =
              data.onlineSection1Attend +
              data.onlineSection2Attend +
              data.onlineSection3Attend +
              data.onlineSection4Attend +
              data.talkSection1Attend +
              data.offlineSection1Attend -
              parseInt(
                (data.onlineSection1Late +
                  data.onlineSection2Late +
                  data.onlineSection3Late +
                  data.onlineSection4Late +
                  data.talkSection1Late +
                  data.offlineSection1Late) /
                  3
              );
          }
        });
      });
    };
    const closedModal = () => {
      modalActive.value = false;
    };

    return {
      modify,
      header,
      page2,
      SEMESTER,
      checkState,
      LOCAL_NAMES_B,
      ROOM_NUMBER,
      TEAM_NUMBER,
      semester,
      year,
      query,
      getDataFromApi,
      tableData,
      newData,
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      verboseSearchOptionManager,
      verboseSearchOptionSemester,
      verboseSearchOptionBranch,
      verboseSearchOptionMaster,
      verboseSearchOptionClassLeader,
      onIsLessonChange1,
      onIsLessonChange2,
      onIsMeetChange,
      onAttendChange,
      modalActive,
      showDetail,
      buldae,
      kkk,
      closedModal,
      gradeChange,
      bsection,
      ksection,
      bsection2,
      ksection2,
      isLesson1,
      isLesson2,
      BLESSON_NAMES,
      KLESSON_NAMES,
      BLESSON_NAMES2,
      KLESSON_NAMES2,
      section1Name,
      section2Name,
      section3Name,
      section4Name,
      section1Number,
      section2Number,
      section3Number,
      section4Number,
      talkNumber,
      offlineNumber,
      totalNumber,
    };
  },
  mounted() {
    const modal = document.querySelectorAll(".modal-inner");
    const modal2 = document.querySelectorAll(".modal-content");
    if (this.$store.state.darkMode) {
      for (var i = 0; i < modal.length; i++) {
        modal[i].style.backgroundColor = "#172b4d";
        modal2[i].style.backgroundColor = "#172b4d";
      }
    }
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.modal-content {
  display: flex;
  flex-direction: column;
}

table {
  width: 100%;
  text-align: left;
}
table th {
  padding: 12px;
  border-bottom: 2px solid darkgray;
  text-align: center;
  background-color: rgba(184, 219, 196, 0.1);
}
table td {
  padding: 12px;
  text-align: center;
}
table tr:nth-of-type(even) {
  background-color: rgba(226, 226, 245, 0.1);
}
.parent {
  display: flex;
  padding: 6px;
}
.parent .child {
  flex: 1;
  text-align: center;
}
p {
  font-weight: bold;
}
</style>

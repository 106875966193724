<!-- 조별 주소 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h3 text-white d-inline-block mb-0">활동가공지사항</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- t: top, b: bottom, s: left, e: right x: left & rigith y: top & bottom -->
            <div class="row my-3 mx-1" style="position: relative">
              <div class="col-sm-1">
                <preset-select
                  v-model="query.bound"
                  size="sm"
                  :choices="[
                    ['5', '5'],
                    ['10', '10'],
                    ['15', '15'],
                    ['20', '20'],
                  ]"
                  @change="getDataFromApi(1)"
                />
              </div>
              <div
                class="col"
                style="position: absolute; bottom: 0px; left: 120px"
              >
                total: {{ total }}
              </div>
              <div
              v-if="verboseSearchOptionManager"
                class="col position-absolute top-0 text-end"
              >
                <base-button
                  type="primary"
                  calss="btn"
                  size="small"
                  @click="goManager"
                >
                  <i class="fa fa-plus"></i>&nbsp;&nbsp;활동가 공지 관리
                </base-button>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-flush">
                <thead>
                  <th v-for="item in header" :key="item.index">{{ item }}</th>
                </thead>
                <tbody>
                  <tr v-for="row in tableData" :key="row.index">
                    <td class="w-5">{{ row.no }}</td>
                    <td class="w-5">{{ row.grade }}</td>
                    <td class="w-70">
                      <h5 class="clickable" @click="toggleBody(row)">
                        <template v-if="row.head">[{{ row.head }}]</template>
                        <div class="font-weight-bold">
                          {{ row.title }}
                          <argon-badge
                            v-show="row.isNew"
                            variant="gradient"
                            class="me-2"
                            size="sm"
                            color="primary"
                            >new</argon-badge
                          >
                        </div>
                      </h5>
                      <div v-if="row.hidden">
                        <hr />
                        <span v-dompurify-html="row.post" class="text-wrap"></span>
                      </div>
                    </td>

                    <td>{{ row.name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- End List -->
            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                :rows-per-page="perPage"
                :total="total"
                :model-value="page2"
                @page-change="getDataFromApi"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>

      <!--End tables-->
    </div>
  </div>
</template>

<script>
import * as api from "@/api";
import { ref } from "vue";
import PresetSelect from "@/components/Inputs/PresetSelect";
import ArgonBadge from "@/components/ArgonBadge.vue";
import { useRouter } from "vue-router";

export default {
  components: {
    PresetSelect,
    ArgonBadge,
  },

  setup() {
    const router = useRouter();
    const tableData = ref([]);
    const perPage = ref(20); // 페이지당 레코드 수
    let page2 = ref(1);
    const total = ref(0); // 총 레코드 수
    const header = ref(["No", "학 년", "제 목", "게시자"]);
    const newData = ref({});
    let verboseSearchOptionManager = ref(false);
    const query = ref({
      bound: 20,
    });

    const token = api.Auth.tokenContent;
    query.value.bound = token.listType;

    const getDataFromApi = (page = 1) => {
      page2.value = page;
      const url = `manage/class2/main?bound=${query.value.bound}
   &page=${page}&state=1&grade=${token.grade}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        let newNoticeDate = new Date();
        newNoticeDate.setDate(newNoticeDate.getDate() - 3);
        if (data.rows > 0) {
          for (const row of d) {
            row.hidden = false;
            row.isNew = new Date(row.createdTime) > newNoticeDate;
          }
        }
        tableData.value = d;
        total.value = data.total;
      });
    };
    const created = () => {
      // 페이지 생성 시 호출
      const token = api.Auth.tokenContent;
      verboseSearchOptionManager.value =
        token.role.includes("manager") || token.role.includes("master");
    };
    created();
    getDataFromApi(1);

    const goManager = () => {
      router.push("/manager/noticeManager");
    };
    const toggleBody = (row) => {
      row.hidden = !row.hidden;
    };

    return {
      header,
      page2,
      newData,
      getDataFromApi,
      tableData,
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      verboseSearchOptionManager,
      goManager,
      toggleBody,
      query,
    };
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.modal-content {
  display: flex;
  flex-direction: column;
}

table {
  width: 100%;
  text-align: left;
}
table th {
  padding: 12px;
  border-bottom: 2px solid darkgray;
  text-align: center;
  background-color: rgba(184, 219, 196, 0.1);
}
table td {
  padding: 12px;
  text-align: center;
}
table tr:nth-of-type(even) {
  background-color: rgba(226, 226, 245, 0.1);
}
</style>

/**
=========================================================
* Vue Argon Dashboard 2 PRO - v3.0.1
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import vue3GoogleLogin from "vue3-google-login";
import VueDOMPurifyHTML from "vue-dompurify-html";

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueDOMPurifyHTML, {
  default: {
    ADD_TAGS: ["iframe"],
  },
  hooks: {
    afterSanitizeAttributes: (node) => {
      // Do something with the node
      if ("target" in node) {
        node.setAttribute("target", "_blank");
        node.setAttribute("rel", "noopener");
      }
    },
  },
});
appInstance.use(vue3GoogleLogin, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
});
appInstance.use(VueSweetalert2);
appInstance.use(ArgonDashboard);
appInstance.use(ElementPlus);
appInstance.mount("#app");
